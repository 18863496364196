import ClientEvaluationFirstStep from './steps/ClientEvaluationFirstStep';
import ClientEvaluationSecondStep from './steps/ClientEvaluationSecondStep';
import ClientEvaluationThirdStep from './steps/ClientEvaluationThirdStep';
import ClientEvaluationFourthStep from './steps/ClientEvaluationFourthStep';
import ClientEvaluationErrorStep from './steps/ClientEvaluationErrorStep';
import { useClientEvaluationContext } from '../ClientEvaluation.Provider';
import { useMemo } from 'react';

import type { ComponentType } from 'react';

type EvaluationSteps = {
  [key: number]: ComponentType;
};

const evaluationSteps: EvaluationSteps = {
  1: ClientEvaluationFirstStep,
  2: ClientEvaluationSecondStep,
  3: ClientEvaluationThirdStep,
  4: ClientEvaluationFourthStep,
  5: ClientEvaluationErrorStep
};

export default function ClientEvaluationSteps() {
  const { currentStep } = useClientEvaluationContext();
  const Step = useMemo(
    () => evaluationSteps[currentStep] ?? ClientEvaluationFirstStep,
    [currentStep]
  );

  return <Step />;
}
