import clsx from 'clsx';
import { useClientEvaluationContext } from '../../ClientEvaluation.Provider';
import ClientEvaluationForm from '../../../ClientEvaluationForm/ClientEvaluationForm';
import { useTranslation } from 'next-i18next';
import { useClientEvaluation } from '../../../../../services/lead/lead.service';

import styles from './../ClientEvaluationSteps.module.css';

export default function ClientEvaluationSecondStep() {
  const { t } = useTranslation('common');
  const { currentStep, goToNextStep, evaluationHash } =
    useClientEvaluationContext();
  const { data } = useClientEvaluation(evaluationHash);
  const clientName = data?.client?.name;
  const headingContent = clientName
    ? t('help-us-find-name', { clientName })
    : t('help-us-find');

  const afterSubmit = () => {
    goToNextStep();
  };

  return (
    <div
      className={clsx(
        styles.step,
        styles.secondStep,
        currentStep === 2 && styles.activeStep
      )}
    >
      <div>
        <h3>{headingContent}</h3>
        <p>{t('complete-form')}</p>
      </div>
      <div className={styles.form}>
        <ClientEvaluationForm afterSubmit={afterSubmit} />
      </div>
    </div>
  );
}
