import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'next-i18next';

import styles from './Input.module.css';
import useDefaultValue from '../../../hooks/forms/useDefaultValue';

interface IBooleanInputProps {
  label: string | JSX.Element;
  name: string;
  validation?: any;
  defaultValue?: boolean;
}

export default function BooleanInput({
  label,
  name,
  validation,
  defaultValue
}: IBooleanInputProps) {
  const { t } = useTranslation('common');
  const {
    setValue,
    watch,
    formState: { errors }
  } = useFormContext();
  useDefaultValue({ defaultValue, name });
  const hasError = !!errors[name];
  const currentValue = watch(name);

  const handleChange = (value: boolean | undefined) => {
    setValue(name, value);
  };

  return (
    <div className={styles.booleanInput}>
      <span className={styles.booleanLabel}>{label}</span>
      <label
        htmlFor={`checkbox-${name}-true`}
        className={clsx(styles.checkbox)}
      >
        <input
          type="checkbox"
          id={`checkbox-${name}-true`}
          onChange={e => handleChange(e.target.checked ? true : undefined)}
          checked={currentValue === true}
        />
        <span className={clsx(hasError && styles.labelWithError)}>
          {t('yes')}
        </span>
      </label>
      <label
        htmlFor={`checkbox-${name}-false`}
        className={clsx(styles.checkbox)}
      >
        <input
          type="checkbox"
          id={`checkbox-${name}-false`}
          onChange={e => handleChange(e.target.checked ? false : undefined)}
          checked={currentValue === false}
        />
        <span className={clsx(hasError && styles.labelWithError)}>
          {t('no')}
        </span>
      </label>
    </div>
  );
}
