import React from 'react';
import { useQuery } from 'react-query';
import * as locationService from '../../../../shared/services/locations/locations.service';
import { UseQueryOptions } from 'react-query/types/react/types';
import { ILocation } from '../../../models/location/location.model';
import { IDistrict } from '../../../models/location/district.model';
import { useRouter } from 'next/router';

export interface ISearchLocationContext {
  searchTerm: string;
  setSearchTerm: (str: string) => any;
  district?: IDistrict;
  setDistrict: (district?: IDistrict) => any;
  selectedLocations: number[];
  setSelectedLocations: (locations: number[]) => void;
  instantAgentPlaceId: number[] | null;
  setInstantAgentPlaceId: (placeId: number[] | null) => void;
}

export const SearchLocationContext =
  React.createContext<ISearchLocationContext>({} as ISearchLocationContext);

export const useSearchLocationContext = () =>
  React.useContext(SearchLocationContext);

export const useLocationQuery = (
  searchTerm: string,
  options?: UseQueryOptions<ILocation[]>,
  withListings = true
) => {
  const { route } = useRouter();
  const context = route.includes('sales') ? 'sales' : 'listing';

  return useQuery<ILocation[]>(
    ['location', searchTerm, withListings, context],
    () => locationService.searchLocation(searchTerm, withListings, context),
    {
      ...options,
      staleTime: 1000 * 60 * 10
    }
  );
};

export interface ISearchLocationProviderProps {
  initialDistrict?: IDistrict;
}

export const SearchLocationProvider: React.FC<ISearchLocationProviderProps> = ({
  initialDistrict,
  children
}) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [district, setDistrict] = React.useState<IDistrict | undefined>(
    initialDistrict || undefined
  );
  const [selectedLocations, setSelectedLocations] = React.useState<number[]>(
    []
  );
  const [instantAgentPlaceId, setInstantAgentPlaceId] = React.useState<
    number[] | null
  >(null);
  const { asPath, query, locale } = useRouter();
  const { data: countries } = locationService.useGroupedDistrictsQuery();

  const _setSearchTerm = React.useCallback(
    (_searchTerm: string) =>
      _searchTerm !== searchTerm && setSearchTerm(_searchTerm),
    [searchTerm, setSearchTerm]
  );

  React.useEffect(() => {
    if (district && !selectedLocations.length) {
      setSelectedLocations([0]);
    }
  }, [district, selectedLocations]);

  React.useEffect(() => {
    if (!district) {
      localStorage.removeItem('placeIds');
      return localStorage.removeItem('cityName');
    }

    setSelectedLocations([0]);
    _setSearchTerm('');
    localStorage.setItem('cityName', district.title);
  }, [district]);

  React.useEffect(() => {
    const overrideData = (dialCode: string, language?: string) => {
      const trigger = (key: string, newValue: string) => {
        window.dispatchEvent(
          new StorageEvent('storage', {
            key,
            newValue
          })
        );
      };

      const userData = JSON.parse(localStorage.getItem('userData') || '{}');
      userData.phoneCode = dialCode;
      if (language) {
        userData.language = language;
      }

      localStorage.setItem('userData', JSON.stringify(userData));

      trigger('dialCode', dialCode);

      if (language) {
        trigger('language', language);
      }
    };

    if (district && district.dialCode) {
      overrideData(district.dialCode, locale);
    } else if (locale && countries?.length) {
      const country = countries.find(c => c.code.toLowerCase() === locale);

      if (country) {
        overrideData(country.dialCode, locale);
      }
    }
  }, [locale, district, countries]);

  React.useEffect(() => {
    if (!instantAgentPlaceId) return;

    localStorage.setItem('placeIds', JSON.stringify(instantAgentPlaceId));
  }, [instantAgentPlaceId]);

  React.useEffect(() => {
    const isCurrentDistrict = asPath.includes(
      district?.title.toLocaleLowerCase() || ''
    );

    if (district && isCurrentDistrict) {
      const { place_in } = query || { place_in: [] };

      if (!place_in) return;

      if (typeof place_in === 'string' || typeof place_in === 'number')
        return setSelectedLocations([parseInt(place_in + '')]);

      const places: number[] = place_in.map((place: any) => {
        return parseInt(place);
      });

      return setSelectedLocations(places);
    }
  }, [query, district]);

  const value = {
    searchTerm,
    setSearchTerm: _setSearchTerm,
    district,
    setDistrict,
    selectedLocations,
    setSelectedLocations,
    instantAgentPlaceId,
    setInstantAgentPlaceId
  };

  return (
    <SearchLocationContext.Provider value={value}>
      {children}
    </SearchLocationContext.Provider>
  );
};
