import { useMutation } from 'react-query';
import { FormFields } from '../../components/common/InstantAgentForm/InstantAgentForm';
import { IListing } from '../../models/listing/listing.model';
import { leadService } from '../../services';
import { IClientEvaluationData } from '../../components/common/ClientEvaluationForm/ClientEvaluationForm';

export interface IClientEvaluationMutationData {
  listing?: IListing;
  formFields: FormFields;
  payload?: any;
}

export const useClientEvaluationMutation = () => {
  return useMutation(
    ({ data }: { data: Partial<IClientEvaluationData> }) => {
      const body = {
        ...data
      };
      return leadService.sendClientEvaluation(body);
    },
    {
      onError: () => {
        console.log('client evaluation error');
      },
      onSuccess: response => {
        // console.log(response);
        // if (!['DUPLICATE'].includes(response.status)) {
        //   showClientEvaluationSuccessDialog();
        // } else {
        //   showClientEvaluationErrorDialog();
        // }
      }
    }
  );
};
