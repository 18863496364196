import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { PHONE_CODES } from '../../../const/phone.const';
import * as flags from 'country-flag-icons/string/3x2';
import InputName from './InputName';

import styles from './Input.module.css';

interface IPhoneInputProps {
  name: string;
  placeholder?: string;
}

interface IPhoneCode {
  country: string;
  code: string;
  iso: string;
}

PHONE_CODES.sort((a, b) => {
  if (a.iso === 'ES') return -1;
  if (b.iso === 'ES') return 1;
  return 0;
});

export default function PhoneInput({
  name,
  placeholder = ''
}: IPhoneInputProps) {
  const {
    register,
    formState: { errors },
    setValue,
    watch
  } = useFormContext();
  const hasError = !!errors[name];
  const [phoneCode] = watch(['phoneCode']) || [34];
  const selectedPhoneCode = PHONE_CODES.find(
    ({ code }) => code === phoneCode
  ) as IPhoneCode;
  const [showPhoneCodes, setShowPhoneCodes] = useState(false);
  const [filter, setFilter] = useState<any>('');

  const togglePhoneCodes = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setShowPhoneCodes(prev => !prev);
  };

  const filteredPhoneCodes = filter
    ? PHONE_CODES.filter(
        ({ country, code }) =>
          country?.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
          code?.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
          ('+' + code).toLocaleLowerCase().includes(filter.toLocaleLowerCase())
      )
    : PHONE_CODES;

  useEffect(() => {
    const userLang = navigator?.language;

    if (!userLang) return;

    const userLangCountry = userLang.split('-')[1];

    if (userLangCountry) {
      filteredPhoneCodes.sort((a, b) => {
        if (a.iso === userLangCountry) return -1;
        if (b.iso === userLangCountry) return 1;
        return 0;
      });
    }
  }, [filteredPhoneCodes]);

  useEffect(() => {
    setFilter('');
  }, [showPhoneCodes]);

  return (
    <div className={clsx(styles.phoneInputContainer, hasError && styles.error)}>
      <button
        className={styles.currentPhoneCode}
        onClick={e => togglePhoneCodes(e)}
      >
        <span
          className={styles.flag}
          dangerouslySetInnerHTML={{
            __html: flags[selectedPhoneCode?.iso as keyof typeof flags]
          }}
        />
        <span className={styles.phoneCodeEl}>+{selectedPhoneCode?.code}</span>
      </button>
      <input
        type="tel"
        placeholder={placeholder}
        className={clsx(
          styles.phoneInput,
          selectedPhoneCode?.code?.length > 2 && 'tw-translate-x-2'
        )}
        {...register(name, {
          required: true,
          pattern: /^[0-9\s]{8,10}$/,
          setValueAs: (value: string) => value.replace(/\s/g, '')
        })}
      />
      <div
        className={clsx(
          showPhoneCodes
            ? `tw-fixed tw-inset-0 tw-z-[99] tw-cursor-pointer`
            : 'tw-hidden'
        )}
        onClick={() => setShowPhoneCodes(false)}
      />
      <div
        className={clsx(
          styles.phoneCodesContainer,
          showPhoneCodes && styles.phoneCodesActive,
          showPhoneCodes && `tw-z-[100]`
        )}
      >
        <div>
          <input
            type="text"
            value={filter}
            onChange={e => setFilter(e.target.value)}
            placeholder="Search"
            className={styles.phoneCodesSearch}
          />
        </div>
        <div className={styles.phoneCodesList}>
          {filteredPhoneCodes.map(({ code, country, iso }, index) => (
            <div
              key={index}
              className={styles.phoneCodesListEl}
              onClick={() => {
                setValue('phoneCode', code);
                setShowPhoneCodes(false);
              }}
            >
              <span
                className={styles.flag}
                dangerouslySetInnerHTML={{
                  __html: flags[iso as keyof typeof flags]
                }}
              />
              <span className={styles.phoneCodeEl}>
                {`${country} (+${code})`}
              </span>
            </div>
          ))}
        </div>
      </div>
      <InputName condition={true} name={placeholder} />
    </div>
  );
}
