import { useForm, FormProvider } from 'react-hook-form';
import {
  Button,
  InputContainer,
  PhoneInput,
  PrivacyPolicyCheckbox,
  SingleSelect,
  SingleSelectWithGroups,
  TextArea,
  TextInput
} from '../Input';
import { useLeadFormMutation } from '../../../hooks/mutations/useLeadFormMutation';
import { useLeadFormData } from '../../../hooks/forms/useLeadFormData';
import { useListingId } from '../../../../views/ExploreDetailsView/ExploreDetails.Context';
import { useTranslation } from 'next-i18next';
import { debounce, flatten } from 'lodash';
import { useMemo, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useGroupedDistrictsQuery } from '../../../services/locations/locations.service';

import styles from './LeadForm.module.css';

export type ILeadFormInputs = {
  name: string;
  surname: string;
  phoneCode?: string;
  phone: string;
  email: string;
  language?: string;
  message: string;
  terms?: boolean;

  listing?: number;
  cityId?: number;
  place?: number[];
  priceFrom?: number;
  priceTo?: number;
  numberOfBeds?: number;
};

interface ILeadFormProps {
  afterSubmit?: () => void;
}

export default function LeadForm({ afterSubmit }: ILeadFormProps) {
  const [showDistricts, setShowDistricts] = useState(true);
  const { t } = useTranslation('common');
  const formMethods = useForm<ILeadFormInputs>({
    defaultValues: {
      phoneCode: '34',
      terms: false
    }
  });
  const {
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting }
  } = formMethods;
  const { mutateAsync, isLoading: isSending } = useLeadFormMutation();
  const listingId = useListingId();
  const { route } = useRouter();
  useLeadFormData({ watch, setValue });
  const { data: countries } = useGroupedDistrictsQuery();
  const districts = useMemo(
    () => flatten(countries?.map(({ cities }) => cities)) || [],
    [countries]
  );

  useEffect(() => {
    if (listingId || route.includes('cityTitle')) {
      return setShowDistricts(false);
    }
  }, [route, listingId]);

  useEffect(() => {
    const cityName = localStorage.getItem('cityName');

    if (cityName) {
      const city = districts.find(d => d.title === cityName);

      if (city) {
        setValue('cityId', city.id);
      }
    }
  }, [districts]);

  const handleSubmit_ = async (data: ILeadFormInputs) => {
    data.phone = data.phoneCode + data.phone;
    data.phone = data.phone.replace(/\s/g, '');

    delete data.terms;
    delete data.phoneCode;

    const cityName = localStorage.getItem('cityName');
    const selectedCityName = districts.find(d => d.id === data.cityId)?.title;
    const cityIdFromSearch = districts.find(d => d.title === cityName)?.id;

    if (!cityName && selectedCityName) {
      localStorage.setItem('cityName', selectedCityName);
    }

    if (!data?.cityId && cityIdFromSearch) {
      data.cityId = cityIdFromSearch;
    }

    if (listingId) {
      data.listing = parseInt(`${listingId}`);
    } else {
      const priceFrom = localStorage.getItem('lower');
      const priceTo = localStorage.getItem('upper');
      const placeIds = localStorage.getItem('placeIds');
      const bedsCount = localStorage.getItem('bedsCount');

      delete data.listing;

      if (placeIds) {
        data.place = JSON.parse(placeIds) as number[];
      }

      if (bedsCount) {
        data.numberOfBeds = Number(bedsCount);
      }

      if (priceFrom && Number(priceTo) > 0) {
        data.priceFrom = Number(priceFrom);
      }

      if (priceTo && Number(priceTo) < 5000) {
        data.priceTo = Number(priceTo);
      }
    }

    if (showDistricts && data?.cityId && selectedCityName !== cityName) {
      delete data.place;
    }

    try {
      await mutateAsync({ data });
    } catch (e) {
      console.log(e);
    }

    afterSubmit && afterSubmit();
  };
  const debouncedHandleSubmit = useMemo(
    () => debounce(handleSubmit_, 500),
    [showDistricts, districts]
  );

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(debouncedHandleSubmit)}
        data-form="contact-agent"
      >
        <div className={styles.inputs}>
          <InputContainer>
            <TextInput
              name={'name'}
              placeholder={t('name')}
              validation={{
                required: true,
                maxLength: 50,
                validate: (value: string) => value?.trim()?.length > 1,
                setValueAs: (value: string) => value?.trim()
              }}
            />
            <TextInput
              name={'surname'}
              placeholder={t('surname')}
              validation={{
                required: true,
                maxLength: 50,
                validate: (value: string) => value?.trim()?.length > 1,
                setValueAs: (value: string) => value?.trim()
              }}
            />
          </InputContainer>
          <InputContainer>
            <PhoneInput name={'phone'} placeholder={t('contact-number')} />
          </InputContainer>
          <InputContainer>
            <TextInput
              name={'email'}
              placeholder={t('email-address')}
              validation={{
                required: true,
                minLength: 3,
                maxLength: 100,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
              }}
            />
          </InputContainer>
          <InputContainer>
            {showDistricts && (
              <SingleSelectWithGroups
                name="cityId"
                placeholder={t('city')}
                validation={{ required: true }}
                groups={countries?.map(({ id, name, cities }) => ({
                  id,
                  name,
                  values: cities.map(({ id, title }) => ({
                    value: id,
                    label: title
                  }))
                }))}
              />
            )}
            <SingleSelect
              name="language"
              placeholder={t('language')}
              options={[
                {
                  value: 'es',
                  label: 'Español'
                },
                {
                  value: 'en',
                  label: 'English'
                },
                {
                  value: 'pt',
                  label: 'Português'
                }
              ]}
              validation={{ required: true }}
            />
          </InputContainer>
          <InputContainer>
            <TextArea
              placeholder={t('additional-comments')}
              rows={3}
              name="message"
            />
          </InputContainer>
          <PrivacyPolicyCheckbox />
        </div>
        <div className={styles.buttons}>
          <Button
            variant="primary"
            disabled={isSubmitting || isSending}
            label={t('chat-with-agent')}
          />
        </div>
      </form>
    </FormProvider>
  );
}
