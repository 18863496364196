import { useQuery } from 'react-query';
import http from '../../../lib/fetch';
import { IDistrict } from '../../models/location/district.model';
import { IJoinHouzitInputs } from 'shared/components/common/JoinHouzit/JoinHouzitForm';

interface IAvatar {
  id: number;
  name: string;
  size: number;
  ext: string;
  url: string;
}

export interface IAgent {
  id: number;
  name: string;
  surname: string;
  position: string;
  email: string;
  phone: string;
  avatar: null | IAvatar;
  isListingAgent: boolean | null;
  district: IDistrict;
  spokenLanguages: string[];
  description: string;
  reflink: string;
}

export const getAgentData = (
  reflink: string,
  listingId: null | number = null
) => {
  return http.post<IAgent>(`/agents/${reflink}`, { listingId }).then(res => {
    res.reflink = reflink;
    return res;
  });
};

export const useAgentData = (
  reflink: string,
  listingId: null | number = null
) => {
  const queryKey = ['agent', reflink];

  if (listingId) {
    queryKey.push(String(listingId));
  }

  return useQuery(queryKey, () => getAgentData(reflink, listingId), {
    staleTime: 1000 * 60 * 10
  });
};

export const registerInterest = (body: IJoinHouzitInputs) => {
  return http.post('/agents/register-interest', body);
};
