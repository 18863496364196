// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const APP_ENV = process.env.APP_ENV || process.env.NEXT_PUBLIC_APP_ENV;

Sentry.init({
  dsn:
    APP_ENV === 'local'
      ? null
      : SENTRY_DSN ||
        'https://701aaac801734c7fb8fe924f40378af8@o1333272.ingest.sentry.io/5843530',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  environment: process.env.APP_ENV || process.env.NEXT_PUBLIC_APP_ENV
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
