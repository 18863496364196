import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { useClientEvaluationContext } from '../ClientEvaluation/ClientEvaluation.Provider';

import styles from './LeadPopUpButton.module.css';

interface ILeadPopUpButtonProps {
  translationKey?: string;
  className?: string;
}

export default function LeadPopUpButton({
  translationKey = 'instant',
  className
}: ILeadPopUpButtonProps) {
  const { t } = useTranslation(['common']);
  const { setPopUpOpen } = useClientEvaluationContext();

  const handleClick = () => {
    setPopUpOpen(true);
  };

  return (
    <button className={clsx(styles.button, className)} onClick={handleClick}>
      <span>{t(translationKey)}</span>
    </button>
  );
}
