import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

import styles from './Input.module.css';

interface IButtonProps {
  label: string | JSX.Element;
  onClick?: (e: any) => void;
  variant?: 'primary' | 'secondary' | 'light';
  disabled?: boolean;
  className?: string;
}

export default function Button({
  label,
  onClick = () => false,
  variant = 'primary',
  className,
  disabled = false
}: IButtonProps) {
  return (
    <button
      className={twMerge(
        clsx(
          styles.button,
          styles[variant],
          className,
          disabled && styles.disabled
        )
      )}
      onClick={onClick}
    >
      {label}
    </button>
  );
}
