import { useTranslation } from 'next-i18next';
import { useState, useEffect } from 'react';
import { Button } from '../Input';
import Link from 'next/link';
import PersonalizeCookies from './PersonalizeCookies';
import Script from 'next/script';

export default function CookiesPopUp() {
  const [isVisible, setIsVisible] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [showPersonalizeCookies, setShowPersonalizeCookies] = useState(false);
  const { t } = useTranslation('common');

  useEffect(() => {
    const cookiesConfigured = localStorage.getItem('cookiesConfigured');

    if (!cookiesConfigured) {
      setIsVisible(true);
    }
  }, []);

  useEffect(() => {
    if (isUpdated) {
      setIsVisible(false);
    }
  }, [isUpdated]);

  const handleAccept = (analytics = true, profiling = true) => {
    localStorage.setItem('cookiesConfigured', 'true');
    localStorage.setItem('useAnalytics', analytics.toString());
    localStorage.setItem('useProfiling', profiling.toString());

    setIsUpdated(true);
  };

  if (!isVisible) return null;

  return (
    <>
      <div
        id="cookies-pop-up"
        className={`tw-fixed tw-flex tw-items-center tw-px-5 tw-py-5 tw-bottom-0 tw-left-0 tw-right-0 tw-min-h-[70px] tw-bg-houzit-text tw-z-[200] tw-text-white xl:px-0 tw-animate-init-top tw-[animation-delay:300ms] ${
          !isVisible ? 'tw-hidden' : ''
        }`}
      >
        <div className="tw-max-w-[1175px] tw-mx-auto tw-w-full tw-flex tw-flex-wrap tw-items-center tw-gap-5 md:tw-flex-nowrap">
          <div className="tw-w-full md:tw-w-auto tw-h-fit tw-text-chip">
            <h6 className="tw-mb-3 tw-text-caption">{t('cookies-header')}</h6>
            <p className="tw-inline">{t('cookies-content')} </p>
            <Link
              href={t('privacy-policy-link')}
              locale={false}
              target="_blank"
              rel="noopener noreferrer"
              className="tw-w-fit tw-text-primary tw-underline tw-whitespace-nowrap"
            >
              {t('cookies-policy')}
            </Link>
            .
          </div>
          <div
            className={`md:tw-ml-auto tw-grid-cols-2 tw-w-full md:tw-w-fit tw-grid md:tw-flex tw-gap-5 tw-items-center`}
          >
            <Button
              label={t('cookies-personalize')}
              variant="secondary"
              onClick={() => setShowPersonalizeCookies(prev => !prev)}
              className="tw-text-caption tw-w-[160px] !tw-text-white tw-mx-auto"
            />
            <Button
              label={t('cookies-accept')}
              variant="primary"
              onClick={() => handleAccept(true, true)}
              className="tw-text-caption tw-w-[160px] tw-mx-auto"
            />
          </div>
        </div>
      </div>
      {showPersonalizeCookies && (
        <PersonalizeCookies handleAccept={handleAccept} />
      )}
      {isUpdated && (
        <Script id="google-tag-manager-consent-update" strategy="lazyOnload">
          {`
            function updateConsent() {
              const useAnalytics = localStorage.getItem('useAnalytics');
              const useProfiling = localStorage.getItem('useProfiling');
          
              gtag('consent', 'update', {
                ad_storage: useAnalytics === 'true' ? 'granted' : 'denied',
                analytics_storage: useAnalytics === 'true' ? 'granted' : 'denied',
                personalization_storage: useProfiling === 'true' ? 'granted' : 'denied'
              });  
              gtag('event', 'consent_update');              
            }

            if (typeof gtag === 'undefined') {
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
            } 

            updateConsent();
           `}
        </Script>
      )}
    </>
  );
}
