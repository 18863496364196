import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import * as contactEn from '../../public/locales/en/contact.json';
import * as exploreEn from '../../public/locales/en/explore.json';
import * as exploreDetailsEn from '../../public/locales/en/explore-details.json';
import * as heroEn from '../../public/locales/en/hero.json';
import * as resultsEn from '../../public/locales/en/results.json';
import * as sharedEn from '../../public/locales/en/common.json';
import * as valuesEn from '../../public/locales/en/values.json';
import * as homepageEn from '../../public/locales/en/homepage.json';

import * as contactEs from '../../public/locales/es/contact.json';
import * as exploreEs from '../../public/locales/es/explore.json';
import * as exploreDetailsEs from '../../public/locales/es/explore-details.json';
import * as heroEs from '../../public/locales/es/hero.json';
import * as resultsEs from '../../public/locales/es/results.json';
import * as sharedEs from '../../public/locales/es/common.json';
import * as valuesEs from '../../public/locales/es/values.json';
import * as homepageEs from '../../public/locales/es/homepage.json';

import * as contactCa from '../../public/locales/ca/contact.json';
import * as exploreCa from '../../public/locales/ca/explore.json';
import * as exploreDetailsCa from '../../public/locales/ca/explore-details.json';
import * as heroCa from '../../public/locales/ca/hero.json';
import * as resultsCa from '../../public/locales/ca/results.json';
import * as sharedCa from '../../public/locales/ca/shared.json';
import * as valuesCa from '../../public/locales/ca/values.json';
import * as homepageCa from '../../public/locales/ca/homepage.json';

import * as contactPt from '../../public/locales/pt/contact.json';
import * as explorePt from '../../public/locales/pt/explore.json';
import * as exploreDetailsPt from '../../public/locales/pt/explore-details.json';
import * as heroPt from '../../public/locales/pt/hero.json';
import * as resultsPt from '../../public/locales/pt/results.json';
import * as sharedPt from '../../public/locales/pt/common.json';
import * as valuesPt from '../../public/locales/pt/values.json';
import * as homepagePt from '../../public/locales/pt/homepage.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    lng: 'en',
    fallbackLng: ['en', 'es'],
    // TODO add missing languages when needed
    supportedLngs: ['en', 'es', 'pt'],
    interpolation: {
      escapeValue: false
    },
    ns: [
      'contact',
      'explore',
      'exploreDetails',
      'hero',
      'results',
      'shared',
      'homepage'
    ],
    defaultNS: 'explore',
    load: 'languageOnly',
    resources: {
      en: {
        contact: contactEn,
        explore: exploreEn,
        exploreDetails: exploreDetailsEn,
        hero: heroEn,
        results: resultsEn,
        common: sharedEn,
        values: valuesEn,
        homepage: homepageEn
      },
      es: {
        contact: contactEs,
        explore: exploreEs,
        exploreDetails: exploreDetailsEs,
        hero: heroEs,
        results: resultsEs,
        common: sharedEs,
        values: valuesEs,
        homepage: homepageEs
      },
      ca: {
        contact: contactCa,
        explore: exploreCa,
        exploreDetails: exploreDetailsCa,
        hero: heroCa,
        results: resultsCa,
        common: sharedCa,
        values: valuesCa,
        homepage: homepageCa
      },
      pt: {
        contact: contactPt,
        explore: explorePt,
        exploreDetails: exploreDetailsPt,
        hero: heroPt,
        results: resultsPt,
        common: sharedPt,
        values: valuesPt,
        homepage: homepagePt
      }
    },
    detection: {
      order: ['navigator', 'localStorage']
    }
  });

export default i18n;
