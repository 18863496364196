import React, { useState } from 'react';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import InputName from './InputName';

import styles from './Input.module.css';

interface ISelectOption {
  value: string | number | boolean | null;
  label: string;
}

interface ISignleSelectProps {
  name: string;
  placeholder: string;
  options: ISelectOption[];
  afterChange?: ((value?: string[] | number[]) => void) | null;
  firstSelectsAll?: boolean;
}

export default function MultiSelect({
  name,
  placeholder,
  options = [],
  afterChange = null,
  firstSelectsAll = false
}: ISignleSelectProps) {
  const { setValue, watch } = useFormContext();
  const [isOpen, setIsOpen] = useState(false);
  const [value] = watch([name]);
  const hasAllSelected = firstSelectsAll
    ? value?.length === options.length - 1
    : value?.length === options.length;
  let label = options
    .filter(option => value?.includes(option.value))
    .map(option => option.label)
    .join(', ');

  if (hasAllSelected && firstSelectsAll) {
    const [firstOption] = options;

    label = firstOption?.label || label;
  }

  const toggleOpen = () => {
    setIsOpen(prev => !prev);
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    toggleOpen();
  };

  const handleChooseOption = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    option: ISelectOption
  ) => {
    e.preventDefault();

    const hasOption = value?.includes(option.value);
    const isFirstOption = option.value === null;

    if (firstSelectsAll && isFirstOption) {
      if (!value || value?.length === options.length - 1) {
        setValue(name, []);
      } else {
        const newValue = options
          .map(option => option.value)
          .filter(option => option !== null);

        setValue(name, newValue);
      }
    } else if (hasOption) {
      setValue(
        name,
        value.filter((item: string | number) => item !== option.value)
      );
    } else {
      setValue(name, [...(value || []), option.value]);
    }

    afterChange && afterChange();
  };

  const handleOverlayClick = () => {
    setIsOpen(false);
  };

  return (
    <div
      className={clsx(
        styles.singleSelectContainer,
        isOpen && styles.singleSelectActive
      )}
    >
      {isOpen && (
        <div
          className={styles.singleSelectOverlay}
          onClick={() => handleOverlayClick()}
        />
      )}
      <button
        className={clsx(styles.singleSelect, !label && styles.isPlaceholder)}
        onClick={e => handleClick(e)}
      >
        <span>{label || placeholder}</span>
      </button>
      <InputName condition={!!label} name={placeholder} />

      <div className={clsx(styles.singleOptionsList, styles.multiSelect)}>
        {options.map(option => {
          const isSelected =
            value?.includes(option.value) ||
            (firstSelectsAll && value?.length == options.length - 1);

          return (
            <button
              className={clsx(
                styles.singleOption,

                isSelected && styles.singleOptionSelected
              )}
              onClick={e => handleChooseOption(e, option)}
              key={option.label}
            >
              <span>{option.label}</span>
              <span className={styles.checkbox}>
                <input type="checkbox" checked={isSelected} />
              </span>
            </button>
          );
        })}
      </div>
    </div>
  );
}
