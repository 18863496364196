import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

export interface ISidebarContextState {
  isOpen: boolean;
  toggle: () => any;
  close: () => any;
}

export const SidebarContext = React.createContext<ISidebarContextState>(
  {} as ISidebarContextState
);

export const useSidebarContext = () => React.useContext(SidebarContext);

export const SidebarProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { events } = useRouter();

  const toggle = () => setIsOpen(prev => !prev);
  const close = () => setIsOpen(false);

  useEffect(() => {
    events.on('routeChangeStart', close);
    return () => {
      events.off('routeChangeStart', close);
    };
  }, [events]);

  return (
    <SidebarContext.Provider value={{ isOpen, toggle, close }}>
      {children}
    </SidebarContext.Provider>
  );
};
