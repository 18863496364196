import styles from './Input.module.css';

interface IInputNameProps {
  condition: boolean;
  name?: string;
}

export default function InputName({ condition, name }: IInputNameProps) {
  if (!condition || !name) {
    return null;
  }

  return <span className={styles.inputName}>{name}</span>;
}
