import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import InputName from './InputName';

import styles from './Input.module.css';

interface ITextAreaProps {
  placeholder?: string;
  rows?: number;
  name?: string;
}

export default function TextArea({
  placeholder = '',
  rows = 2,

  name = ''
}: ITextAreaProps) {
  const {
    register,
    formState: { errors }
  } = useFormContext();
  const hasError = !!errors[name];

  return (
    <div className={clsx(styles.textInputContainer)}>
      <textarea
        placeholder={placeholder}
        className={clsx(
          styles.textInput,
          styles.textArea,
          hasError && styles.error
        )}
        rows={rows}
        {...register(name, {
          minLength: 3,
          maxLength: 1000
        })}
      />
      <InputName condition={true} name={placeholder} />
    </div>
  );
}
