import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import InputName from './InputName';
import _ from 'lodash';

import styles from './Input.module.css';

interface ISelectGroup {
  id: number;
  name: string;
  values: ISelectOption[];
}

interface ISelectOption {
  value: string | number | boolean;
  label: string;
}

interface ISignleSelectWithGroupsProps {
  name: string;
  placeholder: string;
  groups?: ISelectGroup[];
  afterChange?: ((value?: string | number | boolean) => void) | null;
  validation?: any;
}

export default function SingleSelectWithGroups({
  name,
  placeholder,
  groups = [],
  afterChange = null,
  validation = { required: false }
}: ISignleSelectWithGroupsProps) {
  const {
    setValue,
    watch,
    formState: { errors },
    register,
    trigger,
    unregister
  } = useFormContext();
  const [isOpen, setIsOpen] = useState(false);
  const [value] = watch([name]);
  const options = _.flatten(groups.map(group => group.values));
  const { label } = options.find(option => option.value === value) || {};
  const hasError = !!errors[name];

  const toggleOpen = () => {
    setIsOpen(prev => !prev);
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    toggleOpen();
  };

  const handleChooseOption = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    option: ISelectOption
  ) => {
    e.preventDefault();

    setValue(name, option.value);
    trigger(name);
    toggleOpen();

    afterChange && afterChange();
  };

  const handleOverlayClick = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    register(name, validation);
  }, [register, name, validation]);

  useEffect(() => {
    return () => {
      unregister(name);
    };
  }, []);

  return (
    <div
      className={clsx(
        styles.singleSelectContainer,
        isOpen && styles.singleSelectActive,
        hasError && styles.error
      )}
    >
      {isOpen && (
        <div
          className={styles.singleSelectOverlay}
          onClick={() => handleOverlayClick()}
        />
      )}
      <button
        className={clsx(styles.singleSelect, !label && styles.isPlaceholder)}
        onClick={e => handleClick(e)}
      >
        <span>{label || placeholder}</span>
      </button>
      <InputName condition={!!label} name={placeholder} />

      <div className={styles.singleOptionsList}>
        {groups.map(group => (
          <div key={group.id}>
            <div className={styles.groupName}>{group.name}</div>
            {group.values.map(option => (
              <button
                className={clsx(styles.singleOption)}
                onClick={e => handleChooseOption(e, option)}
                key={option.label}
              >
                {option.label}
              </button>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
