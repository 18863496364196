import ClientEvaluationMenu from './parts/menu/ClientEvaluationMenu';
import ClientEvaluationSteps from './parts/ClientEvaluationSteps';

export default function ClientEvaluation() {
  return (
    <>
      <ClientEvaluationMenu />
      <ClientEvaluationSteps />
    </>
  );
}
