import { ChangeEvent } from 'react';

interface ISwitchProps {
  id: string;
  disabled?: boolean;
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: (checked: boolean) => void;
}

export default function Switch({
  id,
  disabled,
  checked,
  defaultChecked,
  onChange
}: ISwitchProps) {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e?.target?.checked);
  };

  return (
    <label className="tw-relative tw-inline-flex tw-items-center tw-cursor-pointer">
      <input
        id={id}
        type="checkbox"
        value=""
        className="tw-sr-only tw-peer"
        onChange={handleChange}
        disabled={disabled}
        {...(checked ? { checked: true } : {})}
        defaultChecked={defaultChecked}
      />
      <div className="tw-w-11 tw-h-6 tw-bg-gray-200 tw-rounded-full peer-focus:tw-ring-1 peer-focus:tw-ring-secondary peer-checked:after:tw-translate-x-full peer-checked:after:tw-border-white after:tw-content-[''] after:tw-absolute after:tw-top-0.5 after:tw-left-[2px] after:tw-bg-white after:tw-border-gray-200 after:tw-border after:tw-rounded-full after:tw-h-5 after:tw-w-5 after:tw-transition-all peer-checked:tw-bg-secondary peer-disabled:tw-opacity-80"></div>
    </label>
  );
}
