import React from 'react';
import { useRouter } from 'next/router';
import { PaginationType } from '../../../models/paginaton/pagination.model';
import { IResultsQueryParams } from '../../../../pages/rent-property/[cityTitle]';

export interface IHistoryCacheContext {
  cache: string[];
  last?: string;
  beforeLast?: string;
  push: (string: string) => void;
  paginationCache?: PaginationType<IResultsQueryParams>;
  setPaginationCache: (
    paginationCache: PaginationType<IResultsQueryParams>
  ) => void;
}

export const HistoryCacheContext = React.createContext<IHistoryCacheContext>(
  {} as IHistoryCacheContext
);

export const useHistoryCache = () => React.useContext(HistoryCacheContext);

export const HistoryCacheProvider: React.FC = ({ children }) => {
  const router = useRouter();

  const [cache, setCache] = React.useState<string[]>([]);
  const [paginationCache, setPaginationCache] =
    React.useState<PaginationType<IResultsQueryParams>>();

  const last = cache[cache.length - 1];
  const beforeLast = cache[cache.length - 2];

  const onRouteChangeComplete = React.useCallback(
    (url: string) => setCache([...cache, url]),
    [cache, setCache]
  );

  const push = (string: string) => {
    if (string !== last) {
      setCache(prev => [...prev, string]);
    }
  };

  React.useEffect(() => {
    router.events.on('routeChangeComplete', onRouteChangeComplete);
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [onRouteChangeComplete]);

  const value = {
    cache,
    last,
    beforeLast,
    push,
    paginationCache,
    setPaginationCache
  };

  return (
    <HistoryCacheContext.Provider value={value}>
      {children}
    </HistoryCacheContext.Provider>
  );
};
