import { useTranslation } from 'next-i18next';
import { languages } from '../../../const/language.const';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import i18nIonic from '../../../i18n/i18n';

import styles from './HorizontalLanguageSelector.module.css';
import { getAlternateRoutes } from '../../../../lib/i18n/getTranslateRoutes';

export default function HorizontalLanguageSelector() {
  const { t, i18n } = useTranslation(['common']);
  const { language } = i18n;
  const router = useRouter();

  return (
    <div className={styles.container}>
      <span>{t('language')}: </span>
      <div className={styles.buttons}>
        {languages &&
          languages.map(lang => (
            <button
              key={lang.value}
              className={clsx(
                styles.button,
                language === lang.value && styles.active
              )}
              onClick={() => {
                const newLng = lang?.value;
                const newUrl = getAlternateRoutes(
                  language,
                  newLng,
                  router.asPath
                ).replace(/#.*$/, '');

                localStorage.setItem('user_lng', newLng);

                i18n.changeLanguage(newLng);
                i18nIonic.changeLanguage(newLng);
                router.push(newUrl, newUrl, { locale: newLng });
              }}
            >
              <img src={lang.path} alt={lang.text} loading="lazy" />
              <span>{lang.short}</span>
            </button>
          ))}
      </div>
    </div>
  );
}
