import Link from 'next/link';
import Checkbox from './Checkbox';
import { Trans, useTranslation } from 'next-i18next';

import type { ReactNode } from 'react';

export default function PrivacyPolicyCheckbox() {
  const { t } = useTranslation('common');

  return (
    <Checkbox
      label={
        <Trans
          i18nKey={'terms-agree-label'}
          components={{
            terms: <BlankLink url={t('terms-conditions-link')} />,
            privacy: <BlankLink url={t('privacy-policy-link')} />
          }}
        />
      }
      id={'t-terms'}
      name={'terms'}
      validation={{ required: true }}
    />
  );
}

const BlankLink = ({
  url,
  children
}: {
  url: string;
  children?: ReactNode;
}) => (
  <Link href={url} locale={false} target="_blank" rel="noopener noreferrer">
    {children}
  </Link>
);
