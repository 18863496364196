import { create } from 'zustand';

interface IJoinHouzitStoreProps {
  isDialogOpen: boolean;
}

interface IJoinHouzitStoreActions {
  setIsDialogOpen: (isDialogOpen: boolean) => void;
}

type IJoinHouzitStore = IJoinHouzitStoreProps & IJoinHouzitStoreActions;

const defaultState: IJoinHouzitStore = {
  isDialogOpen: false,
  setIsDialogOpen: () => {
    return;
  }
};

const useJoinHouzitStore = create<IJoinHouzitStore>(set => ({
  ...defaultState,
  setIsDialogOpen: isDialogOpen => set({ isDialogOpen })
}));

export default useJoinHouzitStore;
