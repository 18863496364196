import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';

import styles from './Input.module.css';

interface ICheckboxProps {
  label: string | JSX.Element;
  id: string;
  name: string;
  validation?: any;
}

export default function Checkbox({
  label,
  id,
  name,
  validation
}: ICheckboxProps) {
  const {
    register,
    formState: { errors }
  } = useFormContext();
  const hasError = !!errors[name];

  return (
    <label htmlFor={`checkbox-${id}`} className={clsx(styles.checkbox)}>
      <input
        type="checkbox"
        id={`checkbox-${id}`}
        {...register(name, validation)}
      />
      <span className={clsx(hasError && styles.labelWithError)}>{label}</span>
    </label>
  );
}
