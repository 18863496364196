const defaultDialCode = '34';

export const defaultDistrict: IDistrict = {
  id: 1,
  title: 'Barcelona',
  dialCode: defaultDialCode
};

export const defaultDistrictPerCountry: IGroupedDistrict[] = [
  {
    id: 1,
    name: 'Spain',
    code: 'ES',
    dialCode: defaultDialCode,
    language: 'es',
    localName: 'España',
    cities: [defaultDistrict]
  }
];

export interface IDistrict {
  id: number;
  title: string;
  dialCode?: string;
  latitude?: number;
  longitude?: number;
}

export interface ICountry {
  id: number;
  name: string;
  code: string;
  dialCode: string;
  language: string;
  localName: string;
}

export interface IGroupedDistrict extends ICountry {
  cities: IDistrict[];
}
