import clsx from 'clsx';
import { Button } from '../../../Input';
import { useClientEvaluationContext } from '../../ClientEvaluation.Provider';
import { useTranslation } from 'next-i18next';

import styles from './../ClientEvaluationSteps.module.css';

export default function ClientEvaluationFourthStep() {
  const { t } = useTranslation();
  const { evaluationHash, currentStep, goToNextStep, isFormPage } =
    useClientEvaluationContext();

  return (
    <div
      className={clsx(
        styles.step,
        currentStep === 4 && styles.activeStep,
        styles.fourthStep
      )}
    >
      {evaluationHash ? (
        <div>
          <img
            src="/images/bird.svg"
            alt=""
            loading="lazy"
            className={styles.img}
          />
          <h3>{t('message-sent')}</h3>
          <p>{t('thank-you-for')}</p>
        </div>
      ) : (
        <div className="tw-text-center tw-text-defaultColor">
          <img
            src="/assets/icons/success.svg"
            alt=""
            className="tw-mx-auto tw-mb-10"
          />
          <h6 className={'tw-text-subtitle tw-mb-5'}>{t('way')}</h6>
          <p className={'tw-text-caption'}>{t('message')}</p>
        </div>
      )}
      <div className={styles.buttons}>
        <Button
          variant="primary"
          label={isFormPage ? t('visit-houzit') : t('view-more-properties')}
          onClick={() => goToNextStep()}
        />
      </div>
    </div>
  );
}
