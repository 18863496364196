import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useClientEvaluationContext } from '../ClientEvaluation/ClientEvaluation.Provider';
import {
  Button,
  InputContainer,
  InputWithTopLabel,
  NumberInput,
  SingleSelect,
  TextInput,
  BooleanInput,
  MultiCheckbox
} from '../Input';
import { useClientEvaluationMutation } from '../../../hooks/mutations/useClientEvaluationMutation';
import { useClientEvaluation } from '../../../services/lead/lead.service';
import { useTranslation } from 'next-i18next';
import { NATIONALITIES_VALUES } from '../../../const/nationalities.const';

import type { IClientEvaluationData } from './ClientEvaluationForm';

import styles from './ClientEvaluationForm.module.css';

export default function ClientEvaluationFormPart2() {
  const { t } = useTranslation();
  const [hasTenants, setHasTenants] = useState<false | number>(false);
  const formMethods = useForm<any>();
  const { handleSubmit, setValue, watch } = formMethods;
  const { goToNextStep, goToLastStep, evaluationHash } =
    useClientEvaluationContext();
  const { mutate } = useClientEvaluationMutation();
  const { data: savedEvaluation } = useClientEvaluation(evaluationHash);
  const tenantsCount = watch('numberOfTenants');

  const onSubmit = (data: IClientEvaluationData) => {
    if (!data.lead) {
      const leadId = localStorage.getItem('leadId');

      if (!leadId) return;

      data.lead = parseInt(leadId);
    }

    mutate({ data });

    if (!hasTenants) {
      return setHasTenants(data.numberOfTenants);
    }

    goToNextStep();
  };

  const handleNextClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    if (!hasTenants && !tenantsCount) {
      setValue('numberOfTenants', savedEvaluation?.numberOfTenants || 1);
      return;
    }

    handleSubmit(onSubmit)();
  };

  useEffect(() => {
    if (savedEvaluation) {
      setValue('numberOfTenants', savedEvaluation?.numberOfTenants);
      setValue('lead', savedEvaluation?.lead);
    }
  }, [savedEvaluation]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {!hasTenants ? (
          <div className={styles.formPart2}>
            <InputContainer>
              <InputWithTopLabel label={t('how-many-people')}>
                <SingleSelect
                  placeholder={t('number-of-tenants')}
                  name="numberOfTenants"
                  options={[
                    { label: '1', value: 1 },
                    { label: '2', value: 2 },
                    { label: '3', value: 3 },
                    { label: '4', value: 4 }
                  ]}
                />
              </InputWithTopLabel>
            </InputContainer>
          </div>
        ) : (
          <div className={styles.tenantsContainer}>
            {hasTenants &&
              Array.from(Array(hasTenants).keys()).map(tenantNumber => (
                <SingleTenant
                  key={tenantNumber}
                  tenantNumber={tenantNumber}
                  tenantData={watch(`tenants.${tenantNumber}`)}
                  defaultData={savedEvaluation?.tenants?.[tenantNumber] || {}}
                />
              ))}
          </div>
        )}

        <div className={styles.buttons}>
          <Button
            variant="secondary"
            label={t('skip')}
            onClick={() => goToLastStep()}
          />
          <Button
            variant="primary"
            label={t('next')}
            onClick={e => handleNextClick(e)}
          />
        </div>
      </form>
    </FormProvider>
  );
}

interface ISingleTenantProps {
  tenantNumber: number;
  tenantData?: any;
  defaultData?: any;
}

const SingleTenant = ({
  tenantNumber,
  tenantData,
  defaultData
}: ISingleTenantProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.tenant}>
      <p>
        {t('tenant')} {+tenantNumber + 1}
      </p>
      <div>
        {defaultData?.id && (
          <div className="tw-hidden">
            <NumberInput
              name={`tenants.${tenantNumber}.id`}
              placeholder={t('id')}
              defaultValue={defaultData.id}
            />
          </div>
        )}
        <InputContainer>
          <NumberInput
            name={`tenants.${tenantNumber}.age`}
            placeholder={t('age')}
            validation={{
              pattern: /^[0-9]{0,3}$/,
              setValueAs: (value: string) => value.replace(/\s/g, ''),
              valueAsNumber: true
            }}
            defaultValue={defaultData?.age}
          />
          {tenantNumber === 0 ? (
            <SingleSelect
              name={`tenants.${tenantNumber}.nationality`}
              placeholder={t('nationality')}
              defaultValue={defaultData?.nationality}
              options={NATIONALITIES_VALUES}
              withSearch={true}
            />
          ) : (
            <span />
          )}
        </InputContainer>
        {tenantNumber === 0 && (
          <>
            <InputContainer>
              <TextInput
                name={`tenants.${tenantNumber}.occupation`}
                placeholder={t('occupation')}
                defaultValue={defaultData?.occupation}
              />
              <NumberInput
                name={`tenants.${tenantNumber}.income`}
                placeholder={`${t('annual-income')} €`}
                validation={{
                  pattern: /^[0-9\s]{0,10}$/,
                  setValueAs: (value: string) => value.replace(/\s/g, ''),
                  valueAsNumber: true
                }}
                defaultValue={defaultData?.income}
              />
            </InputContainer>
            <InputContainer>
              <MultiCheckbox
                label={t('residency-permit')}
                name={`tenants.${tenantNumber}.documentType`}
                defaultValue={defaultData?.documentType}
                options={[
                  { translation: 'yes-dni', value: 'DNI' },
                  { translation: 'yes-nie', value: 'NIE' },
                  { translation: 'no', value: 'No' }
                ]}
              />
            </InputContainer>
            <InputContainer>
              <BooleanInput
                label={t('payslips-question')}
                name={`tenants.${tenantNumber}.payslips`}
                defaultValue={defaultData?.payslips}
              />
            </InputContainer>
            {tenantData?.payslips === false && (
              <InputContainer>
                <BooleanInput
                  label={t('solvency-question')}
                  name={`tenants.${tenantNumber}.economicSolvency`}
                  defaultValue={defaultData?.economicSolvency}
                />
              </InputContainer>
            )}
            <InputContainer>
              <BooleanInput
                label={t('contract-question')}
                name={`tenants.${tenantNumber}.employmentContract`}
                defaultValue={defaultData?.employmentContract}
              />
            </InputContainer>
            <InputContainer>
              <BooleanInput
                label={t('insurance-question')}
                name={`tenants.${tenantNumber}.insurance`}
                defaultValue={defaultData?.insurance}
              />
            </InputContainer>
          </>
        )}
      </div>
    </div>
  );
};
