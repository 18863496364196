export const DEFAULT_LANG = 'en';
export const SECONDARY_LANG = 'es';

export interface LanguageElement {
  value: string;
  text: string;
  short: string;
  path: string;
}

export const languages: LanguageElement[] = [
  {
    value: 'es',
    text: 'Español',
    short: 'ES',
    path: '/assets/icons/country/es.svg'
  },
  {
    value: 'en',
    text: 'English',
    short: 'EN',
    path: '/assets/icons/country/en.svg'
  },
  {
    value: 'pt',
    text: 'Portugués',
    short: 'PT',
    path: '/assets/icons/country/pt.svg'
  }
  // {
  //   value: 'ca',
  //   text: t('catalan'),
  //   short: 'CA',
  //   path: '/assets/icons/catalonia.svg'
  // }
];

export const validLocales = languages.map(lang => lang.value);
