import {
  DEFAULT_LANG,
  SECONDARY_LANG
} from '../../shared/const/language.const';

interface IRoute {
  path: string;
  name: string;
}

interface IRoutes {
  [lang: string]: IRoute[];
}

const routes: IRoutes = {
  default: [],
  es: [
    { path: '/alquiler-viviendas', name: 'listing' },
    { path: '/ventas', name: 'sales' },
    {
      path: '/contacto-agente',
      name: 'contact-agent'
    }
  ],
  en: [
    { path: '/rent-property', name: 'listing' },
    { path: '/sales', name: 'sales' },
    {
      path: '/contact-agent',
      name: 'contact-agent'
    }
  ],
  pt: [
    { path: '/alugar-imovel', name: 'listing' },
    { path: '/vendas', name: 'sales' },
    {
      path: '/contactar-agente',
      name: 'contact-agent'
    }
  ]
};

routes.default = routes.en;

export const getTranslateRoutes = (locale = DEFAULT_LANG, page: string) => {
  const route = routes[locale].find(route => route.name === page);
  return route ? route.path : '/';
};

export const getAlternateRoutes = (
  oldLng = DEFAULT_LANG,
  newLng = SECONDARY_LANG,
  asPath = '/'
) => {
  const oldRoute = routes[oldLng].find(route => asPath.includes(route.path));

  if (oldRoute) {
    const newRoute = routes[newLng].find(route => route.name === oldRoute.name);

    if (!newRoute) return asPath;

    const newAsPath = asPath.replace(oldRoute.path, newRoute.path);
    return newAsPath;
  }

  return asPath;
};
