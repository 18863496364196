import React from 'react';
import { useQuery } from 'react-query';
import { useRouter } from 'next/router';
import { i18n } from 'next-i18next';
import { listingService, saleService } from '../../shared/services';
import { PaginationType } from '../../shared/models/paginaton/pagination.model';
import { IResultsQueryParams } from '../../pages/rent-property/[cityTitle]';
import { getListing } from '../../shared/services/sale/listing.service';

export enum ExploreDetailForm {
  ContactAgent = 'ContactAgent',
  ScheduleViewing = 'ScheduleViewing'
}

export interface IExploreDetailsContextState {
  // listing?: IListing;
  // features?: Partial<Record<keyof IListingFeatures, boolean | null>>;
  // amenities?: Partial<Record<keyof IListingAmenities, boolean | null>>;
  // similarListings?: IListing[];
  currentForm?: ExploreDetailForm;
  context?: 'listing' | 'sales';
}

export interface IExploreDetailsMethods {
  setCurrentForm: (statement: ExploreDetailForm) => void;
}

export type ExploreDetailsContextType = IExploreDetailsContextState &
  IExploreDetailsMethods;

export const ExploreDetailsContext =
  React.createContext<ExploreDetailsContextType>(
    {} as ExploreDetailsContextType
  );

export const useExploreDetailsContext = () =>
  React.useContext(ExploreDetailsContext);

export const useListingQuery = () => {
  const { context } = useExploreDetailsContext();

  const id = useListingId();
  return useQuery(
    ['listing', id, i18n?.language || 'en'],
    () => {
      if (context === SALES) {
        return saleService.getListing(id);
      }
      return listingService.getListing(id);
    },
    {
      enabled: !!id
    }
  );
};

export const useFeaturesQuery = () => {
  const id = useListingId();
  const { context } = useExploreDetailsContext();

  return useQuery(['listing', 'features', id], () => {
    if (context === SALES) {
      return saleService.getListingFeatures(id);
    }
    return listingService.getListingFeatures(id);
  });
};

export const useAmenitiesQuery = () => {
  const { context } = useExploreDetailsContext();

  const id = useListingId();
  return useQuery(['listing', 'amenities', id], () => {
    if (context === SALES) {
      return saleService.getListingAmenities(id);
    }
    return listingService.getListingAmenities(id);
  });
};

export const useSimilarListingsQuery = () => {
  const { context } = useExploreDetailsContext();
  const id = useListingId();

  return useQuery(
    ['similarListings', id],
    () => {
      if (context === SALES) {
        return saleService.getSimilarListings(Number(id));
      }
      return listingService.getSimilarListings(Number(id));
    },
    {
      enabled: !!id
    }
  );
};

export const useListingId = () => {
  const { query, asPath, pathname } = useRouter();
  const { listingId = '' } = query;

  if (listingId) {
    return String(listingId);
  }

  const urlParts = asPath.split('/');
  const listingIdFromPath = urlParts[urlParts.length - 2];

  if (listingIdFromPath && pathname.includes('[listingId]')) {
    return listingIdFromPath;
  }

  return '';
};

export interface IExploreDetailsContextProps {
  context?: 'listing' | 'sales';
}

export const SALES = 'sales';

export const ExploreDetailsContextProvider: React.FC<IExploreDetailsContextProps> =
  ({ children, context = 'listing' }) => {
    const [currentForm, setCurrentForm] = React.useState<ExploreDetailForm>(
      ExploreDetailForm.ContactAgent
    );

    const value: ExploreDetailsContextType = {
      currentForm,
      context,
      setCurrentForm
    };

    return (
      <ExploreDetailsContext.Provider value={value}>
        {children}
      </ExploreDetailsContext.Provider>
    );
  };
