import Qs from 'qs';
import _omit from 'lodash/omit';

export interface IPagination {
  page?: number;
  pageSize?: number;
  sort?: string;
  pageCount?: number;
  total?: number;
}

export interface IPagedResponse<R = unknown> {
  results: R[];
  pagination: Required<IPagination>;
}

export type PaginationType<T extends any> = IPagination &
  Partial<Record<keyof T, (string | number) | (string | number)[]>>;

export class Pagination<T> {
  constructor(pagination: PaginationType<T>) {
    Object.assign(this, pagination);
  }

  static toURL<T>(pagination: PaginationType<T>, omitDefault = false) {
    const { page } = pagination;
    const hiddenKeys = ['pageCount', 'total', 'cityTitle'];

    if (omitDefault) {
      hiddenKeys.push('availableFirst', 'city');

      if (page === 1) {
        hiddenKeys.push('page');
      }
    }

    const _pagination = _omit(pagination, hiddenKeys);

    return Qs.stringify(_pagination, { arrayFormat: 'repeat' });
  }

  static fromPagedResponse(pagedResponse: IPagedResponse): IPagination {
    const { pagination } = pagedResponse;
    return pagination;
  }
}
