import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import InputName from './InputName';

import styles from './Input.module.css';

type IValue = string | number | boolean;

interface ISelectOption {
  value: IValue;
  label: string;
}

interface ISignleSelectProps {
  name: string;
  placeholder: string;
  options: ISelectOption[];
  defaultValue?: IValue;
  afterChange?: ((value?: IValue) => void) | null;
  validation?: any;
  withSearch?: boolean;
}

export default function SingleSelect({
  name,
  placeholder,
  options = [],
  defaultValue,
  afterChange = null,
  validation = { required: false },
  withSearch = false
}: ISignleSelectProps) {
  const [filter, setFilter] = useState<any>('');
  const {
    setValue,
    watch,
    formState: { errors },
    register,
    trigger
  } = useFormContext();
  const [isOpen, setIsOpen] = useState(false);
  const [value] = watch([name]);
  const { label } = options.find(option => option.value === value) || {};
  const hasError = !!errors[name];

  const toggleOpen = () => {
    setIsOpen(prev => !prev);
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    toggleOpen();
  };

  const handleChooseOption = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    option: ISelectOption
  ) => {
    e.preventDefault();

    setValue(name, option.value);
    trigger(name);
    toggleOpen();

    afterChange && afterChange();
  };

  const handleOverlayClick = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    register(name, validation);
  }, [register, name, validation]);

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue);
      trigger(name);
    }
  }, []);

  return (
    <div
      className={clsx(
        styles.singleSelectContainer,
        isOpen && styles.singleSelectActive,
        hasError && styles.error
      )}
    >
      {isOpen && (
        <div
          className={styles.singleSelectOverlay}
          onClick={() => handleOverlayClick()}
        />
      )}
      <button
        className={clsx(styles.singleSelect, !label && styles.isPlaceholder)}
        onClick={e => handleClick(e)}
      >
        <span>{label || placeholder}</span>
      </button>
      <InputName condition={!!label} name={placeholder} />

      <div className={styles.singleOptionsList}>
        {withSearch && (
          <div>
            <input
              type="text"
              value={filter}
              onChange={e => setFilter(e.target.value)}
              placeholder="Search"
              className={clsx(
                styles.phoneCodesSearch,
                'tw-h-10 tw-text-chip tw-pl-[13px]'
              )}
            />
          </div>
        )}
        {options
          .filter(
            option =>
              filter === '' ||
              option.label.toLowerCase().includes(filter.toLowerCase())
          )
          .map(option => (
            <button
              className={clsx(styles.singleOption)}
              onClick={e => handleChooseOption(e, option)}
              key={option.label}
            >
              {option.label}
            </button>
          ))}
      </div>
    </div>
  );
}
