import axios from 'axios';
import { Pagination } from '../shared/models/paginaton/pagination.model';

const mapEnvironmentName = () => {
  switch (process.env.NEXT_PUBLIC_APP_ENV) {
    default:
      return process.env.NEXT_PUBLIC_APP_ENV;
  }
};

const getBaseUrl = () => {
  switch (mapEnvironmentName()) {
    case 'prod':
      return `https://dashboard.houzit.com/public`;
      break;
    default:
      return (
        process.env.NEXT_PUBLIC_STRAPI_API_URL ||
        `https://dashboard-${mapEnvironmentName()}.houzit.com/public`
      );
  }
};

export const fetch = axios.create({
  paramsSerializer: params => {
    return Pagination.toURL(params);
  },
  baseURL: getBaseUrl()
});

fetch.interceptors.request.use(
  req => req,
  error => Promise.reject(error)
);

fetch.interceptors.response.use(
  res => {
    if (res.status >= 400) {
      return Promise.reject(res.data);
    }
    return res.data;
  },
  err => {
    // error handling on global level
    if (err.isAxiosError) {
      return Promise.reject(err.response.data);
    }
    return Promise.reject(err);
  }
);

export default fetch;
