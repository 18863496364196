import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';

import type { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import type { ILeadFormInputs } from '../../components/common/LeadForm/LeadForm';

interface IUseSavedUserDataProps {
  watch: UseFormWatch<ILeadFormInputs>;
  setValue: UseFormSetValue<ILeadFormInputs>;
}

export const useLeadFormData = ({
  watch,
  setValue
}: IUseSavedUserDataProps) => {
  const [dataInitialized, setDataInitialized] = useState(false);
  const {
    i18n: { language }
  } = useTranslation();
  const data = watch();

  useEffect(() => {
    if (dataInitialized) return;

    const savedData = localStorage.getItem('userData') || '{}';
    const userData = JSON.parse(savedData);

    if (userData) {
      setValue('name', userData?.name);
      setValue('surname', userData?.surname);
      setValue('email', userData?.email);
      setValue('phone', userData?.phone);
      setValue('language', userData?.language || language);

      if (userData?.phoneCode) {
        setValue('phoneCode', userData?.phoneCode);
      }
    }

    setDataInitialized(true);
  }, [dataInitialized]);

  useEffect(() => {
    if (!dataInitialized) return;

    localStorage.setItem('userData', JSON.stringify(data));
  }, [data, dataInitialized]);

  useEffect(() => {
    const handleChange = (e: StorageEvent) => {
      const { newValue, key } = e || {};

      if (newValue) {
        switch (key) {
          case 'dialCode':
            setValue('phoneCode', newValue);
            break;
          case 'language':
            setValue('language', newValue);
            break;
        }
      }
    };

    window.addEventListener('storage', handleChange);

    return () => {
      window.removeEventListener('storage', handleChange);
    };
  }, []);
};
