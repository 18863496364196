import clsx from 'clsx';
import React, { useRef, useEffect, useState } from 'react';
import ClientEvaluation from '../ClientEvaluation';
import { useClientEvaluationContext } from '../ClientEvaluation.Provider';

import styles from './ClientEvaluationPopUp.module.css';

export default function ClientEvaluationPopUp() {
  const { popUpOpen, closePopUp } = useClientEvaluationContext();
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    closePopUp();
  };

  useEffect(() => {
    const handleTransitionEnd = (e: any) => {
      setIsVisible(popUpOpen);
    };

    if (popUpOpen) setIsVisible(true);

    containerRef.current?.addEventListener(
      'transitionend',
      handleTransitionEnd
    );

    return () => {
      containerRef.current?.removeEventListener(
        'transitionend',
        handleTransitionEnd
      );
    };
  }, [popUpOpen]);

  if (!isVisible) return null;

  return (
    <div
      className={clsx(styles.overlay, popUpOpen && styles.popUpOpen, 'popUp')}
      onClick={e => handleClose(e)}
      ref={containerRef}
    >
      <div className={styles.content} onClick={e => e.stopPropagation()}>
        <ClientEvaluation />
        <button className={styles.close} onClick={() => closePopUp()} />
      </div>
    </div>
  );
}
