import { ReactNode } from 'react';

import styles from './Input.module.css';

interface IInputWithTopLabelProps {
  children: ReactNode;
  label: string;
}

export default function InputWithTopLabel({
  children,
  label
}: IInputWithTopLabelProps) {
  return (
    <div className={styles.inputWithTopLabel}>
      <label>{label}</label>
      <div>{children}</div>
    </div>
  );
}
