import clsx from 'clsx';
import LeadForm from '../../../LeadForm/LeadForm';
import { useClientEvaluationContext } from '../../ClientEvaluation.Provider';
import { useTranslation } from 'next-i18next';

import styles from './../ClientEvaluationSteps.module.css';

export default function ClientEvaluationFirstStep() {
  const { t } = useTranslation();
  const { currentStep, goToNextStep } = useClientEvaluationContext();

  return (
    <div
      className={clsx(
        styles.step,
        styles.firstStep,
        currentStep === 1 && styles.activeStep
      )}
    >
      <div>
        <h3>{t('connect-with-agent')}</h3>
        <p>{t('lead-form-desc')}</p>
      </div>
      <div className={styles.form}>
        <LeadForm afterSubmit={goToNextStep} />
      </div>
    </div>
  );
}
