import React from 'react';
import Head from 'next/head';
import type { AppProps } from 'next/app';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Hydrate } from 'react-query/hydration';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { appWithTranslation } from 'next-i18next';
import Script from 'next/script';

import { Sidebar } from '../shared/components/common/Sidebar/Sidebar';
import CookiesPopUp from '../shared/components/common/CookiesPopUp/CookiesPopUp';

import { HistoryCacheProvider } from '../shared/components/common/HistoryCache/HistoryCache';
import { SidebarProvider } from '../shared/components/common/Sidebar/Sidebar.Provider';
import { ClientEvaluationProvider } from '../shared/components/common/ClientEvaluation/ClientEvaluation.Provider';

import '../shared/i18n/i18n';

import '../styles/fonts.css';
import '../styles/variables.css';
import '../styles/globals.css';
import 'react-image-lightbox/style.css';

const isProd = () => process.env.NEXT_PUBLIC_APP_ENV === 'prod';

function MyApp({ Component, pageProps }: AppProps) {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false
          }
        }
      })
  );

  const tagManagerArgs: TagManagerArgs = {
    gtmId: process.env.NEXT_PUBLIC_GTM_ID ?? 'GTM-5Q3GFQT',
    auth: process.env.NEXT_PUBLIC_GTM_AUTH ?? 'xlpy7Q9km8-uKwGRdx2EMA',
    preview: process.env.NEXT_PUBLIC_GTM_PREVIEW ?? 'env-9'
  };

  React.useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <QueryClientProvider contextSharing={true} client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1"
          />
          {!isProd() && <meta name="robots" content="noindex"></meta>}
        </Head>
        <HistoryCacheProvider>
          <Script id="google-tag-manager-consent" strategy="beforeInteractive">
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}

            const useAnalytics = localStorage.getItem('useAnalytics');
            const useProfiling = localStorage.getItem('useProfiling');
        
            gtag('consent', 'default', {
              ad_storage: useAnalytics === 'true' ? 'granted' : 'denied',
              analytics_storage: useAnalytics === 'true' ? 'granted' : 'denied',
              personalization_storage: useProfiling === 'true' ? 'granted' : 'denied'
            });
           `}
          </Script>
          <ClientEvaluationProvider hash={pageProps?.clientEvaluationhash}>
            <SidebarProvider>
              <Component {...pageProps} />
              <Sidebar />
              <CookiesPopUp />
            </SidebarProvider>
          </ClientEvaluationProvider>
        </HistoryCacheProvider>
      </Hydrate>
    </QueryClientProvider>
  );
}

export default appWithTranslation(MyApp);
