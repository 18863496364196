import clsx from 'clsx';
import { useClientEvaluationContext } from '../../ClientEvaluation.Provider';
import ClientEvaluationFormPart2 from '../../../ClientEvaluationForm/ClientEvaluationFormPart2';
import { useTranslation } from 'next-i18next';

import styles from './../ClientEvaluationSteps.module.css';

export default function TenantFormThirdStep() {
  const { t } = useTranslation('common');
  const { currentStep } = useClientEvaluationContext();

  return (
    <div
      className={clsx(
        styles.step,
        styles.thirdStep,
        currentStep === 3 && styles.activeStep
      )}
    >
      <div>
        <h3>{t('tenant-details-header')}</h3>
        <p>{t('tenant-details-desc')}</p>
        <p>{t('tenant-details-desc-2')}</p>
      </div>
      <ClientEvaluationFormPart2 />
    </div>
  );
}
