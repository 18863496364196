import { useState, useEffect, useContext, createContext } from 'react';
import ClientEvaluationPopUp from './popup/ClientEvaluationPopUp';
import { useRouter } from 'next/router';
import { getTranslateRoutes } from '../../../../lib/i18n/getTranslateRoutes';
import { CLIENT_EVALUATION_ENABLED } from '../../../services/lead/lead.service';

import type { ReactNode } from 'react';

export interface IClientEvaluationContext {
  currentStep: number;
  setCurrentStep: (step: number) => void;
  popUpOpen: boolean;
  setPopUpOpen: (open: boolean) => void;
  evaluationHash: string | null;
  setEvaluationHash: (hash: string) => void;
  isFormPage: boolean;
  goToNextStep: () => void;
  goToLastStep: () => void;
  closePopUp: () => void;
  showError: () => void;
  showAfterSubmit: () => void;
}

interface IClientEvaluationProviderParams {
  children: ReactNode;
  hash?: string | null;
}

export const ClientEvaluationContext = createContext<IClientEvaluationContext>(
  {} as IClientEvaluationContext
);

export const ClientEvaluationProvider = ({
  children,
  hash = null
}: IClientEvaluationProviderParams) => {
  const lastStepNb = 4;
  const errorStepNb = 5;
  const isFormPage = !!hash;
  const firstStepNb = isFormPage ? 2 : 1;
  const [currentStep, setCurrentStep] = useState(firstStepNb);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [evaluationHash, setEvaluationHash] = useState<string | null>(hash);
  const { asPath, push, locale, query } = useRouter();

  const goToNextStep = () => {
    let nextStep = currentStep + 1;

    if (currentStep === 1) {
      nextStep = lastStepNb;

      if (CLIENT_EVALUATION_ENABLED) {
        const formViewed = localStorage.getItem('formViewed') || evaluationHash;

        nextStep = formViewed ? lastStepNb : currentStep + 1;
        localStorage.setItem('formViewed', 'true');
      }
    } else if (currentStep === lastStepNb) {
      nextStep = 1;
      setPopUpOpen(false);

      clearHash();

      if (isFormPage) return push(`/`);

      const cityName = localStorage.getItem('cityName') || 'Barcelona';
      const newUrl = `${getTranslateRoutes(
        locale,
        'listing'
      )}/${cityName.toLocaleLowerCase()}`;

      return push(newUrl);
    }

    setCurrentStep(nextStep);
  };

  const showAfterSubmit = () => {
    const formViewed = localStorage.getItem('formViewed');

    if (formViewed || !CLIENT_EVALUATION_ENABLED) {
      goToNextStep();
      setPopUpOpen(true);
      return;
    }

    setCurrentStep(2);
    setPopUpOpen(true);

    localStorage.setItem('formViewed', 'true');
  };

  const goToLastStep = () => {
    setCurrentStep(lastStepNb);
  };

  const resetClientEvaluation = () => {
    setCurrentStep(firstStepNb);
  };

  const closePopUp = () => {
    clearHash();

    resetClientEvaluation();
    setPopUpOpen(false);
  };

  const showError = () => {
    setCurrentStep(errorStepNb);
    setPopUpOpen(true);
  };

  const clearHash = () => {
    setEvaluationHash(null);
    localStorage.removeItem('hashKey');
  };

  const value = {
    currentStep,
    setCurrentStep,
    popUpOpen,
    setPopUpOpen,
    evaluationHash,
    setEvaluationHash,
    isFormPage,
    goToNextStep,
    goToLastStep,
    closePopUp,
    showError,
    showAfterSubmit
  };

  useEffect(() => {
    resetClientEvaluation();
  }, [asPath]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!popUpOpen) return;

      if (event.key === 'Escape') {
        closePopUp();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [popUpOpen]);

  useEffect(() => {
    const hash = query?.hash as string | null;

    if (hash) {
      setEvaluationHash(hash);
      localStorage.setItem('hashKey', hash);
      localStorage.setItem('formViewed', 'true');
      localStorage.removeItem('leadId');
    }
  }, [query]);

  return (
    <ClientEvaluationContext.Provider value={value}>
      {children}
      <ClientEvaluationPopUp />
    </ClientEvaluationContext.Provider>
  );
};

export const useClientEvaluationContext = () =>
  useContext(ClientEvaluationContext);
