import React from 'react';
import { useTranslation } from 'next-i18next';
import { useSidebarContext } from './Sidebar.Provider';
import LeadPopUpButton from '../LeadPopUpButton/LeadPopUpButton';
import clsx from 'clsx';
import HorizontalLanguageSelector from '../LanguageSelector/HorizontalLanguageSelector';
import useJoinHouzitStore from '@/stores/joinHouzitStore';

import CloseIconSVG from '../../../../public/assets/icons/x.svg';

import styles from './Sidebar.module.css';

export const Sidebar = () => {
  const { t } = useTranslation(['common']);
  const { isOpen, close } = useSidebarContext();
  const setIsDialogOpen = useJoinHouzitStore(state => state.setIsDialogOpen);

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    close();
  };

  const preventSidebarClose = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div
      className={clsx(styles.overlay, isOpen && styles.sidebarActive)}
      onClick={handleOverlayClick}
    >
      <div className={styles.sidebar} onClick={preventSidebarClose}>
        <nav>
          <ol className={styles.sidebarNav}>
            <li onClick={close}>
              <LeadPopUpButton translationKey="talk" />
            </li>
            <li>
              <button
                onClick={() => {
                  setIsDialogOpen(true);
                  close();
                }}
              >
                {t('become')}
              </button>
            </li>
            <li>
              <HorizontalLanguageSelector />
            </li>
          </ol>
        </nav>
        <button className={styles.close} onClick={() => close()}>
          <CloseIconSVG />
        </button>
      </div>
    </div>
  );
};
