import { useMutation } from 'react-query';
import { FormFields } from '../../components/common/InstantAgentForm/InstantAgentForm';
import { IListing } from '../../models/listing/listing.model';
import { leadService, saleService } from '../../services';
import { ILeadFormInputs } from '../../components/common/LeadForm/LeadForm';
import { useClientEvaluationContext } from '../../components/common/ClientEvaluation/ClientEvaluation.Provider';

import {
  useExploreDetailsContext,
  SALES
} from '../../../views/ExploreDetailsView/ExploreDetails.Context';

export interface ILeadFormMutationData {
  listing?: IListing;
  formFields: FormFields;
  payload?: any;
}

export const useLeadFormMutation = () => {
  const { showError } = useClientEvaluationContext();
  const { context } = useExploreDetailsContext();

  let formViewed: string | null = null;

  if (typeof window !== 'undefined') {
    formViewed = localStorage.getItem('formViewed');
  }

  return useMutation(
    ({ data }: { data: ILeadFormInputs }) => {
      const body = {
        ...data
      };

      if (context === SALES) {
        return saleService.sendLead(body);
      }

      return leadService.sendLead(body);
    },
    {
      onError: () => {
        showError();
      },
      onSuccess: async response => {
        const leadId = response?.leadId;

        if (leadId) {
          localStorage.setItem('leadId', String(leadId));
        }

        if (leadId && !formViewed) {
          await leadService.createEmptyEvaluation(leadId);
        }

        if (!['DUPLICATE'].includes(response.status)) {
          //   showLeadFormSuccessDialog();
        } else {
          //   showLeadFormErrorDialog();
        }
      }
    }
  );
};
