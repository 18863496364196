import { CancelTokenSource } from 'axios';
import http from '../../../lib/fetch';
import { Listing } from '../../models';
import {
  IListing,
  IListingAmenities,
  IListingFeatures
} from '../../models/listing/listing.model';
import {
  IPagedResponse,
  IPagination,
  Pagination,
  PaginationType
} from '../../models/paginaton/pagination.model';
import { IResultsQueryParams } from '../../../pages/rent-property/[cityTitle]';
import { MAX_PRICE, MIN_PRICE } from '../../const/listing.const';
import { DEFAULT_PAGE_SIZE } from '../../const/pagination.const';
import { useQuery } from 'react-query';
import { i18n } from 'next-i18next';
import {
  CreateRecordResponse,
  InstantAgentRequest,
  ScheduleViewingRequest
} from '../lead/lead.service';

export const search = (
  pagination: PaginationType<IResultsQueryParams> = {
    pageSize: DEFAULT_PAGE_SIZE
  },
  cancelToken?: CancelTokenSource
) => {
  const paramsToOmit: string[] = [
    'fbclid',
    'gclid',
    'utm_campaign',
    'utm_source',
    'utm_medium',
    'utm_content'
  ];
  const paramsToOmit_ = paramsToOmit.reduce(
    (acc: { [key: string]: undefined }, param) => {
      acc[param] = undefined;
      return acc;
    },
    {}
  );

  const sort = pagination.sort || 'created_at:DESC';

  if (
    pagination?.rentalPrice_lte &&
    Number(pagination.rentalPrice_lte) >= MAX_PRICE
  ) {
    delete pagination?.rentalPrice_lte;
  }

  if (
    pagination?.rentalPrice_gte &&
    Number(pagination.rentalPrice_gte) <= MIN_PRICE
  ) {
    delete pagination?.rentalPrice_gte;
  }

  return http.get<IPagedResponse<IListing>>(`/sales`, {
    cancelToken: cancelToken?.token,
    params: { ...pagination, ...paramsToOmit_, sort }
  });
};

export const getSimilarListings = (listingId?: number) => {
  if (!listingId) return Promise.reject();

  return http
    .get<IPagedResponse<IListing>>(`/sales/${listingId}/similar`)
    .then(response => response);
};

export const getListing = (id: string) => http.get<IListing>(`/sales/${id}`);

export const getFormattedPrice = (
  price: number | undefined,
  currency = 'EUR'
) => {
  if (!price) return '';

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    maximumFractionDigits: 0,
    currency
  }).format(price);
};

export interface IFeaturesResponse {
  title: string;
  id: number;
  features: Partial<Record<keyof IListingFeatures, boolean | null>>;
}

export const getListingFeatures = (id: string) =>
  http.get<IFeaturesResponse>(`/sales/${id}/features`);

export interface IAmenitiesResponse {
  title: string;
  id: number;
  amenities: Partial<Record<keyof IListingAmenities, boolean | null>>;
}

export const getListingAmenities = (id: string) =>
  http.get<IAmenitiesResponse>(`/sales/${id}/amenities`);

export const useListingQuery = (listingId: string) => {
  return useQuery(['sales-listing', listingId], () => getListing(listingId), {
    enabled: !!listingId,
    staleTime: 1000 * 60 * 10
  });
};

export const sendLead = (body: InstantAgentRequest) => {
  return http.post<CreateRecordResponse>('/sales', {
    ...body,
    language: body?.language || i18n?.language
  });
};

export const scheduleViewing = (
  body: ScheduleViewingRequest,
  listing: IListing
) => {
  const requestBody: ScheduleViewingRequest = {
    ...body,
    listing: listing.id
  };
  return http.post<CreateRecordResponse>('/sales', requestBody);
};
