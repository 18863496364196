import { useQuery } from 'react-query';
import http from '../../../lib/fetch';
import { IListing } from '../../models/listing/listing.model';
import { i18n } from 'next-i18next';

export const CLIENT_EVALUATION_ENABLED = true;

export interface CreateRecordResponse {
  leadId: number;
  status: 'ALREADY_ACTIVE' | 'FREE_FOR_ALL' | 'DUPLICATE';
  created: boolean;
  agent: null | {
    id: number;
    name: string;
    surname: string;
  };
}

export interface CreateClientEvaluationResponse {
  hashKey: string;
}

export interface CreateRecordError {
  error: string;
  message: 'ALREADY_ACTIVE' | string;
  statusCode: number;
}

export interface ScheduleViewingRequest {
  name: string; // from form
  surname: string; // ffrom form
  message: string; //  from form
  phone: string; // from form
  email: string; // from from
  prefferedDate: string; // from form or set as now when want instant agent
  listing?: number; // from context

  place?: number[];
  priceFrom?: number;
  priceTo?: number;
  numberOfBeds?: number;
}

export const scheduleViewing = (
  body: ScheduleViewingRequest,
  listing: IListing
) => {
  const requestBody: ScheduleViewingRequest = {
    ...body,
    listing: listing.id
  };
  return http.post<CreateRecordResponse>('/leads', requestBody);
};

export interface InstantAgentRequest {
  name: string;
  surname: string;
  message: string;
  phone: string;
  email: string;
  listing?: number;
  language?: string;

  place?: number[];
  priceFrom?: number;
  priceTo?: number;
  numberOfBeds?: number;
}

export interface ClientEvaluationRequest {
  minPrice: number;
  maxPrice: number;
  rentalDuration?: number;
  preferredPlaces?: number[];
  bedrooms?: number;
  petOwner?: boolean;
  lead: number;
  language?: string;
  client?: IEvaluationClient;
}

interface ICity {
  id: number;
  title: string;
}

interface IPlace {
  district: ICity;
  id: number;
  title: string;
}

interface IEvaluationClient {
  id: number;
  name: string;
}

export interface ClientEvaluationResponse {
  id: number;
  lead: number;
  tenants: Tenant[];
  petOwner: boolean;
  bedrooms: number;
  targetMoveInDate: string;
  rentalDuration: number;
  maxPrice: number;
  minPrice: number;
  numberOfTenants: number;
  preferredPlaces: IPlace[];
  city?: ICity;
  client?: IEvaluationClient;
}

interface Tenant {
  insurance: boolean;
  employmentContract: boolean;
  payslips: boolean;
  income: number;
  occupation: string;
  nationality: string;
  age: number;
}

export const instantAgent = (body: InstantAgentRequest, listing?: IListing) => {
  if (listing) {
    body.listing = Number(listing.id);
  }
  return http.post<CreateRecordResponse>('/leads', body);
};

export const sendLead = (body: InstantAgentRequest) => {
  return http.post<CreateRecordResponse>('/leads', {
    ...body,
    language: body?.language || i18n?.language
  });
};

export const sendLeadCapture = (reflink: string, body: InstantAgentRequest) => {
  return http.post<CreateRecordResponse>(`/leads/capture/${reflink}`, {
    ...body,
    language: i18n?.language
  });
};

export const createEmptyEvaluation = async (leadId: number) => {
  if (!CLIENT_EVALUATION_ENABLED) return;

  await sendClientEvaluation({ lead: leadId, language: i18n?.language });
};

export const sendClientEvaluation = async (
  body: Partial<ClientEvaluationRequest>
) => {
  if (body?.client) {
    delete body.client;
  }

  const res = await http.post<CreateClientEvaluationResponse>('/evaluation', {
    ...body,
    language: i18n?.language
  });

  if (res?.hashKey) {
    localStorage.setItem('hashKey', res.hashKey);
  }

  return res;
};

export const getClientEvaluation = (hash: string) => {
  return http.get<ClientEvaluationResponse>(`/evaluation/${hash}`);
};

export const useClientEvaluation = (hash: string | null) => {
  return useQuery(
    ['client-evaluation', hash],
    () => getClientEvaluation(hash as string),
    {
      enabled: !!hash
    }
  );
};
