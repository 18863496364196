import clsx from 'clsx';
import { ReactNode } from 'react';

import styles from './Input.module.css';

interface IInputContainerProps {
  children: ReactNode;
  className?: string | null;
}

export default function InputContainer({
  children,
  className = null
}: IInputContainerProps) {
  return <div className={clsx(styles.container, className)}>{children}</div>;
}
