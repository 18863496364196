import http from '../.././../lib/fetch';
import {
  defaultDistrict,
  defaultDistrictPerCountry,
  IGroupedDistrict
} from '../../models/location/district.model';
import { useQuery } from 'react-query';
import { ILocation } from '../../models/location/location.model';

export const searchLocation = (
  searchTerm: string,
  withListings = true,
  context = 'listing'
) =>
  http
    .get<ILocation[]>(
      `/location/${searchTerm}?withListings=${!!withListings}&context=${context}`
    )
    .then(locations =>
      locations.map(location => {
        if (!location.placeId) location.placeId = 0;

        return location;
      })
    );

export const getDistrict = (cityTitle: string) =>
  http
    .get<IGroupedDistrict[]>(`/location/all/districts/grouped`)
    .then(countries => {
      const allCities = countries.flatMap(country => country.cities);
      const district = allCities.find(
        city => city.title.toLocaleLowerCase() === cityTitle.toLocaleLowerCase()
      );

      return district || defaultDistrict;
    })
    .catch(() => defaultDistrict);

export const getGroupedDistricts = () =>
  http
    .get<IGroupedDistrict[]>(`/location/all/districts/grouped`)
    .then(data => data);

export const useGroupedDistrictsQuery = () => {
  return useQuery(['grouped-districts'], () => getGroupedDistricts(), {
    enabled: true,
    staleTime: 1000 * 60 * 10,
    initialData: defaultDistrictPerCountry,
    initialDataUpdatedAt: 0
  });
};
