import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

interface IUseDefaultValueProps {
  defaultValue: unknown;
  name: string;
}

export default function useDefaultValue({
  defaultValue,
  name
}: IUseDefaultValueProps) {
  const { setValue } = useFormContext();

  useEffect(() => {
    if (defaultValue !== null && defaultValue !== undefined) {
      setValue(name, defaultValue);
    }
  }, []);

  return null;
}
