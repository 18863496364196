import clsx from 'clsx';
import { useClientEvaluationContext } from '../../ClientEvaluation.Provider';
import { useTranslation } from 'next-i18next';

import styles from './ClientEvaluationMenu.module.css';

export default function ClientEvaluationMenu() {
  const { t } = useTranslation(['common']);
  const { currentStep, setCurrentStep, popUpOpen } =
    useClientEvaluationContext();
  const hideMenu = currentStep === 1 || currentStep >= 4;

  const handleStepClick = (newStep: number) => {
    if (newStep > currentStep || newStep === 1) {
      return;
    }

    setCurrentStep(newStep);
  };

  if (hideMenu) return null;

  return (
    <div className={clsx(styles.container, popUpOpen && styles.isPopUp)}>
      {steps.map(({ id, translationKey }) => (
        <button
          key={id}
          className={clsx(
            styles.button,
            currentStep === id && styles.buttonActive,
            currentStep < id && styles.buttonInactive
          )}
          data-step={id}
          onClick={() => handleStepClick(id)}
        >
          <span>{t(translationKey)}</span>
        </button>
      ))}
    </div>
  );
}

const steps = [
  {
    id: 1,
    translationKey: 'personal-details'
  },
  {
    id: 2,
    translationKey: 'enquiry-details'
  },
  {
    id: 3,
    translationKey: 'tenant-details'
  }
];
