import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import React from 'react';
import useDefaultValue from '../../../hooks/forms/useDefaultValue';
import InputName from './InputName';

import styles from './Input.module.css';

interface ITextInputProps {
  name: string;
  placeholder?: string;
  validation?: any;
  defaultValue?: string;
}

export default function NumberInput({
  name,
  placeholder = '',
  validation = { required: false },
  defaultValue
}: ITextInputProps) {
  const {
    register,
    formState: { errors }
  } = useFormContext();
  useDefaultValue({ defaultValue, name });
  const hasError = !!errors[name];

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'e' || e.key === '-' || e.key === '.') {
      e.preventDefault();
    }
  };

  return (
    <div className={clsx(styles.textInputContainer)}>
      <input
        type="number"
        placeholder={placeholder}
        className={clsx(
          styles.textInput,
          styles.numberInput,
          hasError && styles.error
        )}
        onKeyDown={e => handleKeyDown(e)}
        {...register(name, validation)}
      />
      <InputName condition={true} name={placeholder} />
    </div>
  );
}
