import clsx from 'clsx';
import { Button } from '../../../Input';
import { useClientEvaluationContext } from '../../ClientEvaluation.Provider';
import { useTranslation } from 'next-i18next';

import styles from './../ClientEvaluationSteps.module.css';

export default function ClientEvaluationErrorhStep() {
  const { t } = useTranslation();
  const { currentStep, closePopUp } = useClientEvaluationContext();

  return (
    <div
      className={clsx(
        styles.step,
        currentStep === 5 && styles.activeStep,
        styles.fourthStep
      )}
    >
      <div>
        <img
          src="/images/bird.svg"
          alt=""
          loading="lazy"
          className={styles.img}
        />
        <h3>{t('booked')}</h3>
      </div>
      <div className={styles.buttons}>
        <Button
          variant="primary"
          label={t('close')}
          onClick={() => closePopUp()}
        />
      </div>
    </div>
  );
}
