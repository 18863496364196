import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import InputName from './InputName';
import useDefaultValue from '../../../hooks/forms/useDefaultValue';
import { useEffect } from 'react';

import styles from './Input.module.css';

interface ITextInputProps {
  name: string;
  placeholder?: string;
  validation?: any;
  defaultValue?: string;
}

export default function TextInput({
  name,
  placeholder = '',
  validation = { required: false },
  defaultValue
}: ITextInputProps) {
  const {
    register,
    formState: { errors },
    watch,
    setValue
  } = useFormContext();
  useDefaultValue({ defaultValue, name });
  const hasError = !!errors[name];
  const currentValue = watch(name);

  useEffect(() => {
    const toOverride = ['name', 'surname'];

    if (toOverride.includes(name) && currentValue) {
      const newValue = currentValue.replace(
        /[0-9!@#$%^&*()<>?,./\\[\]{}:|_-]/g,
        ''
      );
      setValue(name, newValue);
    }
  }, [currentValue]);

  return (
    <div className={clsx(styles.textInputContainer)}>
      <input
        type="text"
        placeholder={placeholder}
        className={clsx(styles.textInput, hasError && styles.error)}
        {...register(name, validation)}
      />
      <InputName condition={true} name={placeholder} />
    </div>
  );
}
