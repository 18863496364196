import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { Button, Switch } from '../Input';
import { useState } from 'react';

interface IPersonalizeCookiesProps {
  handleAccept: (analytics: boolean, profiling: boolean) => void;
}

export default function PersonalizeCookies({
  handleAccept
}: IPersonalizeCookiesProps) {
  const [useAnalytics, setUseAnalytics] = useState(true);
  const [useProfiling, setUseProfiling] = useState(true);
  const { t } = useTranslation('common');

  const handleSave = () => {
    handleAccept && handleAccept(useAnalytics, useProfiling);
  };

  return (
    <div className="tw-z-[205] tw-fixed tw-top-1/2 tw-left-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2 tw-max-h-[min(600px,_90%)] tw-h-fit tw-w-[min(480px,_90vw)] tw-bg-white tw-p-8 md:tw-px-10 tw-gap-3 tw-flex tw-flex-col tw-rounded-lg tw-shadow-popup tw-overflow-y-auto">
      <div></div>

      <h5 className="tw-text-center tw-font-bold">
        {t('cookies-personalize-title')}
      </h5>

      <div className="tw-text-chip md:tw-text-caption tw-leading-5 tw-space-y-4 tw-text-center">
        <p>{t('cookies-personalize-content')}</p>
        <p>
          <span>{t('cookies-personalize-content-2')} </span>
          <Link
            href={t('privacy-policy-link')}
            locale={false}
            target="_blank"
            rel="noopener noreferrer"
            className="tw-w-fit tw-text-primary tw-underline tw-whitespace-nowrap"
          >
            {t('cookies-policy-2')}
          </Link>
          <span>.</span>
        </p>
      </div>

      <div className="tw-grid tw-grid-cols-1 tw-gap-3">
        <SwitchWithLabel
          label={t('cookies-technical')}
          id="cookies-technical"
          disabled={true}
          checked={true}
        />
        <SwitchWithLabel
          label={t('cookies-analytics')}
          id="cookies-analytics"
          onChange={setUseAnalytics}
          defaultChecked={true}
        />
        <SwitchWithLabel
          label={t('cookies-profiling')}
          id="cookies-profiling"
          onChange={setUseProfiling}
          defaultChecked={true}
        />
      </div>

      <div>
        <Button
          label={t('cookies-confirm')}
          className="tw-mx-auto tw-mt-5 md:tw-mt-10"
          onClick={handleSave}
        />
      </div>
    </div>
  );
}

interface ISwitchWithLabelProps {
  id: string;
  disabled?: boolean;
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: (checked: boolean) => void;
  label: string;
}

const SwitchWithLabel = ({
  label,
  id,
  checked,
  defaultChecked,
  disabled,
  onChange
}: ISwitchWithLabelProps) => {
  return (
    <div className="tw-flex tw-gap-3 tw-p-3 tw-justify-between tw-w-full tw-max-w-[280px] tw-mx-auto">
      <label
        className="tw-cursor-pointer tw-w-full tw-text-chip md:tw-text-caption"
        htmlFor={id}
      >
        {label}
      </label>
      <Switch
        id={id}
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
  );
};
