import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'next-i18next';

import styles from './Input.module.css';
import useDefaultValue from '../../../hooks/forms/useDefaultValue';

interface IValue {
  translation: string;
  value: string;
}

interface IMultiCheckboxProps {
  label: string | JSX.Element;
  name: string;
  defaultValue?: boolean;
  options: IValue[];
}

export default function MultiCheckbox({
  label,
  name,
  options,
  defaultValue
}: IMultiCheckboxProps) {
  const { t } = useTranslation('common');
  const {
    setValue,
    watch,
    formState: { errors }
  } = useFormContext();
  useDefaultValue({ defaultValue, name });
  const hasError = !!errors[name];
  const currentValue = watch(name);

  const handleChange = (value: string | boolean | undefined) => {
    setValue(name, value);
  };

  return (
    <div className={clsx(styles.booleanInput, 'tw-grid-cols-[1fr_auto]')}>
      <span className={clsx(styles.booleanLabel, 'tw-w-fit')}>{label}</span>
      <div className="tw-flex tw-items-center tw-gap-[10px]">
        {options.map(option => (
          <label
            htmlFor={`checkbox-${name}-${option.value}`}
            className={clsx(styles.checkbox)}
            key={option.value}
          >
            <input
              type="checkbox"
              id={`checkbox-${name}-${option.value}`}
              onChange={e =>
                handleChange(e.target.checked ? option.value : undefined)
              }
              checked={currentValue === option.value}
            />
            <span className={clsx(hasError && styles.labelWithError)}>
              {t(option.translation)}
            </span>
          </label>
        ))}
      </div>
    </div>
  );
}
