import { useClientEvaluationContext } from '../ClientEvaluation/ClientEvaluation.Provider';
import { Button, InputContainer, MultiSelect, SingleSelect } from '../Input';
import { useForm, FormProvider } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { useClientEvaluation } from '../../../services/lead/lead.service';
import { useClientEvaluationMutation } from '../../../hooks/mutations/useClientEvaluationMutation';
import { useLocationQuery } from '../SearchLocation/SearchLocation.Provider';
import { useTranslation } from 'next-i18next';

import styles from './ClientEvaluationForm.module.css';

export type IClientEvaluationData = {
  minPrice: number;
  maxPrice: number;
  rentalDuration: number;
  targetMoveInDate: string;
  preferredPlaces: number[];
  bedrooms: number;
  petOwner: boolean;
  lead: number;
  numberOfTenants: number;
};

interface IClientEvaluationFormProps {
  afterSubmit?: () => void;
}

export default function ClientEvaluationForm({
  afterSubmit
}: IClientEvaluationFormProps) {
  const { t } = useTranslation('common');
  const { evaluationHash, goToLastStep, setEvaluationHash } =
    useClientEvaluationContext();
  const { mutate } = useClientEvaluationMutation();
  const formMethods = useForm<IClientEvaluationData>();
  const { handleSubmit, setValue, watch } = formMethods;
  const { data: savedEvaluation } = useClientEvaluation(evaluationHash);
  const [city, setCity] = useState(savedEvaluation?.city?.title || 'Barcelona');
  const { data: places } = useLocationQuery(city);
  const currentMinPrice = watch('minPrice');

  const handleSkipClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    handleSubmit(data => handleSubmit_(data, true))();

    goToLastStep();
  };

  const handleSubmit_ = (data: IClientEvaluationData, isSkip = false) => {
    if (!data.lead) {
      const leadId = localStorage.getItem('leadId');

      if (!leadId) return;

      data.lead = parseInt(leadId);
    }

    if (typeof data.preferredPlaces === 'number') {
      data.preferredPlaces = [data.preferredPlaces];
    }

    if (data.maxPrice < data.minPrice) {
      data.maxPrice = data.minPrice;
    }

    mutate({ data });

    if (!isSkip) {
      afterSubmit && afterSubmit();
    }
  };

  useEffect(() => {
    const defaultCity = localStorage.getItem('cityName');

    if (!savedEvaluation) {
      if (defaultCity) return setCity(defaultCity);

      return;
    }

    const excludedKeys = [
      'id',
      'city',
      'preferredPlaces',
      'tenants',
      'targetMoveInDate'
    ];

    for (const [key, value] of Object.entries(savedEvaluation)) {
      const isValueDefined = value !== undefined && value !== null;

      if (isValueDefined && !excludedKeys.includes(key)) {
        setValue(key as any, value);
      }
    }

    savedEvaluation?.preferredPlaces &&
      setValue(
        'preferredPlaces',
        savedEvaluation?.preferredPlaces?.map(place => place?.id)
      );

    savedEvaluation?.targetMoveInDate &&
      setValue(
        'targetMoveInDate',
        findNearestDate(new Date(savedEvaluation?.targetMoveInDate))
      );

    const cityName = savedEvaluation?.city?.title || defaultCity;

    if (cityName) {
      setCity(cityName);
    }
  }, [savedEvaluation]);

  useEffect(() => {
    const userHash = localStorage.getItem('hashKey');

    if (userHash) {
      setEvaluationHash(userHash);
    }
  }, []);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(data => handleSubmit_(data, false))}>
        <div className={styles.inputs}>
          <InputContainer>
            <SingleSelect
              name={'minPrice'}
              placeholder={t('min-price')}
              options={Array.from({ length: 25 }, (_, i) => ({
                value: (i + 6) * 100,
                label: `${(i + 6) * 100}`
              }))}
            />
            <SingleSelect
              name={'maxPrice'}
              placeholder={t('max-price')}
              options={Array.from({ length: 25 }, (_, i) => ({
                value: (i + 6) * 100,
                label: `${(i + 6) * 100}`
              })).filter(option => option.value >= (currentMinPrice || 600))}
            />
          </InputContainer>
          <InputContainer>
            <SingleSelect
              name="rentalDuration"
              placeholder={t('rental-duration')}
              options={[
                { value: 3, label: `3 ${t('months')}` },
                { value: 6, label: `6 ${t('months')}` },
                { value: 12, label: `12 ${t('months')}` },
                { value: 24, label: `24 ${t('months')}` },
                { value: 36, label: `36 ${t('months')}` }
              ]}
            />
          </InputContainer>
          <InputContainer>
            <SingleSelect
              name="targetMoveInDate"
              placeholder={t('target-date')}
              options={[
                {
                  value: calcDate(1),
                  label: t('asap')
                },
                {
                  value: calcDate(8),
                  label: t('within-1-week')
                },
                {
                  value: calcDate(31),
                  label: t('within-1-month')
                },
                {
                  value: calcDate(60),
                  label: t('more-than-1-month')
                }
              ]}
            />
          </InputContainer>
          {places && (
            <InputContainer>
              <MultiSelect
                name="preferredPlaces"
                placeholder={t('preferred-places')}
                firstSelectsAll={true}
                options={places.map(place => ({
                  value: place.isDistrict ? null : place.placeId,
                  label: place.isDistrict
                    ? `${t('all')} ${place.districtTitle}`
                    : place.placeTitle
                }))}
              />
            </InputContainer>
          )}
          <InputContainer>
            <SingleSelect
              name="bedrooms"
              placeholder={t('bedrooms-nb')}
              options={[
                { value: 1, label: '1' },
                { value: 2, label: '2' },
                { value: 3, label: '3' },
                { value: 4, label: '4+' }
              ]}
            />
            <SingleSelect
              name="petOwner"
              placeholder={t('pet-owner')}
              options={[
                { value: true, label: t('yes') },
                { value: false, label: t('no') }
              ]}
            />
          </InputContainer>
        </div>
        <div className={styles.buttons}>
          <Button
            variant="secondary"
            label={t('skip')}
            onClick={e => handleSkipClick(e)}
          />
          <Button variant="primary" label={t('next')} />
        </div>
      </form>
    </FormProvider>
  );
}

const calcDate = (days: number) => {
  const today = new Date(new Date().setHours(12, 0, 0, 0));
  const oneDay = 24 * 60 * 60 * 1000;

  return getFormattedDate(new Date(today.getTime() + oneDay * days));
};

const getFormattedDate = (date: Date) => {
  return date.toISOString().substring(0, 10);
};

const findNearestDate = (date: Date) => {
  const today = new Date(new Date().setHours(12, 0, 0, 0));
  const oneDay = 24 * 60 * 60 * 1000;

  const diff = Math.round((date.getTime() - today.getTime()) / oneDay);

  if (diff < 7) {
    return calcDate(1);
  } else if (diff < 31) {
    return calcDate(8);
  } else if (diff < 60) {
    return calcDate(31);
  } else {
    return calcDate(60);
  }
};
